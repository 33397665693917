import './StepsToInvest.scss';
import { Button1, PageBanner } from '../../../components'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Link } from 'react-router-dom';
import { stiContents1, stiContents2 } from '../../../common/StepsToInvestConstants';
import backgroundImage from '../../../assets/images/sti-banner.jpg'
import stiImg1 from '../../../assets/images/sti-img1.jpg';
import stiImg3 from '../../../assets/images/sti-img3.jpg';

const StepsToInvest = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='Frequently Asked Questions'
        para='If you are interested to learn more about the capitaleta marketplace or have any questions about the process, you can check out these faqs or contact us.'
        buttonText=''
      />
      <section className='sectionPaddingY'>
        <h2 className='topicHeading text-center'>Steps to Become an Investor</h2>
        <div className="container">
          <div className='sti-tabs-content mb-5'>
            <div className='row'>
              <div className='col-12 col-md-6 sti-contents'>
                <Tabs>
                  <TabList>
                    {
                      stiContents1.map((item, index) => (
                        <Tab>STEP {index + 1}</Tab>
                      ))
                    }
                  </TabList>
                  {
                    stiContents1.map((item, index) => (
                      <TabPanel key={index}>
                        <h5>{item.haeding}</h5>
                        <p>{item.para}</p>
                        {
                          item.buttonText &&
                          <div className='d-flex'>
                            <Link to={item.buttonLink}>
                              <Button1 style={{ backgroundColor: 'var(--secondary)' }}>{item.buttonText} <i class="bi bi-chevron-right"></i></Button1>
                            </Link>
                          </div>
                        }
                      </TabPanel>
                    ))
                  }
                </Tabs>
              </div>
              <div className='col-12 col-md-6 sti-img-block' style={{ backgroundImage: `url(${stiImg1})` }}></div>
            </div>
          </div>
          <div className='sti-tabs-content right-align'>
            <div className='row'>
              <div className='col-12 col-md-6 order-2 order-md-1 sti-img-block' style={{ backgroundImage: `url(${stiImg3})` }}></div>
              <div className='col-12 col-md-6 order-1 order-md-2 sti-contents'>
                <Tabs>
                  <TabList>
                    {
                      stiContents2.map((item, index) => (
                        <Tab>STEP {index + 4}</Tab>
                      ))
                    }
                  </TabList>

                  {
                    stiContents2.map((item, index) => (
                      <TabPanel key={index}>
                        <h5>{item.haeding}</h5>
                        <p>{item.para}</p>
                        {
                          item.buttonText &&
                          <div className='d-flex'>
                            <Link to={item.buttonLink}>
                              <Button1 style={{ backgroundColor: 'var(--secondary)' }}>{item.buttonText} <i class="bi bi-chevron-right"></i></Button1>
                            </Link>
                          </div>
                        }
                      </TabPanel>
                    ))
                  }
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StepsToInvest
