import Logo from "../../assets/images/capitaletaLogo.png";
import './Footer.scss';
import { NavLink, Link } from "react-router-dom";
import {CommonFooter} from "../../components";

const GuestFooter = () => {
    return (
        <footer className="main-footer">
            <div className="container-fluid upper-footer">
                <div className="row">
                    <div className="col-12 col-lg-3 footer-logo pe-5">
                        <NavLink to={'/'}>
                            <img src={Logo} alt="Logo" />
                        </NavLink>
                        <p className="mt-3">CapitaLeta provides a smarter way to raise equity and connect investors to profitable project opportunities.</p>
                    </div>
                    <div className="col-12 col-lg-9">
                        <div className="container-fluid">
                            <div className="row footer-nav">
                                <div className="col-12 col-md">
                                    <h5>About Us</h5>
                                    <ul>
                                        <li>
                                            <NavLink to={'/about'}>Overview</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/about'}>Our People</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/careers'}>Careers</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/contact'}>Contact Us</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md">
                                    <h5>INVESTORS</h5>
                                    <ul>
                                        <li>
                                            <NavLink to={'/how-it-works'}>How it works</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/investors-commitment'}>Our Commitment</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/marketplace'}>Visit Marketplace</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/project-review'}>Project Review Process</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md">
                                    <h5>RESOURCES</h5>
                                    <ul>
                                        <li>
                                            <NavLink to={'/insights'}>Insights</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/investor-relation'}>Investor Relations</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/steps-to-invest'}>Quick Start Guide</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/events-webiners'}>Events and Webinars</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md">
                                    <h5>PROJECT SPONSORS</h5>
                                    <ul>
                                        <li>
                                            <NavLink to={'/capital-raising'}>Raise Equity</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/project-evaluation'}>Project Evaluation</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/marketplace'}>On the Marketplace</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/contact'}>Request Information</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md">
                                    <h5>OUR IMPACT</h5>
                                    <ul>
                                        <li>
                                            <NavLink to={'/philantropy'}>Philanthropy</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/integrated-esg'}>Integrated ESG</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid lower-footer">
                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <ul className="social-icon">
                            <li>
                                <Link to={'/'}><i class="bi bi-instagram"></i></Link>
                            </li>
                            <li>
                                <Link to={'/'}><i class="bi bi-facebook"></i></Link>
                            </li>
                            <li>
                                <Link to={'/'}><i class="bi bi-twitter"></i></Link>
                            </li>
                            <li>
                                <Link to={'/'}><i class="bi bi-linkedin"></i></Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row py-4 mb-4 terms-links">
                    <div className="col"></div>
                    <div className="col">
                        <ul>
                            <li><Link to={'/terms-of-use'}>Terms of Use</Link></li>
                            <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
                            <li><Link to={'/disclosures'}>Disclosures</Link></li>
                        </ul>
                    </div>
                    <div className="col"></div>
                </div>
                <CommonFooter/>
            </div>
        </footer>
    )
}

export default GuestFooter
