import whyinvImg1 from '../assets/images/oic-img1.jpg';
import whyinvImg2 from '../assets/images/oic-img2.jpg';
import whyinvImg3 from '../assets/images/oic-img3.jpg';

export const threeBlockContent = [
    {
        color: 'var(--secondary)',
        haeding: 'Institutional Quality Project',
        imageSrc: whyinvImg1,
        imageAlt: 'whyinvImg1',
        para: 'We are committed to bringing institutional-quality real estate and energy investment opportunities for you to access value creation outside the public markets through long-term private markets investments.'
    },
    {
        color: 'var(--primary)',
        haeding: 'Professional Management',
        imageSrc: whyinvImg2,
        imageAlt: 'whyinvImg2',
        para: 'Highly experienced professional team with an established, successful track record and extensive network that provides you with timely, transparent, complete and accurate information to all investors.'
    },
    {
        color: 'var(--secondary)',
        haeding: 'Low Fees',
        imageSrc: whyinvImg3,
        imageAlt: 'whyinvImg3',
        para: 'Lower fees than traditional private equity, private credit and infrastructure fund models and improving net returns to investors'
    },
]