import investImg1 from '../assets/images/invest-img_03.png';
import investImg2 from '../assets/images/invest-img_05.png';
import investImg3 from '../assets/images/invest-img_07.png';
import investImg4 from "../assets/images/post-img1.jpg";
import investImg5 from "../assets/images/post-img2.jpg";
import investImg6 from "../assets/images/post-img3.jpg";

export const firstThreeBlockContent = [
    {
        color: 'var(--secondary)',
        haeding: 'High-Quality Deal Flow',
        imgSrc: investImg1,
        imgAlt: 'investImg1',
        para: 'Quality deal flow from sourcing new projects for investment is a critical success factor of Capitaleta. Our extensive networks of existing relationships enhance the high-volume deal flow that we are committed to providing our investors.'
    },
    {
        color: 'var(--primary)',
        haeding: 'Invest Hands-Free',
        imgSrc: investImg2,
        imgAlt: 'investImg2',
        para: 'We take care of deal sourcing, due diligence, risk underwriting and managing the project investment to assure high performance and return.  You may be certain that your investments are in capable hands.'
    },
    {
        color: 'var(--secondary)',
        haeding: 'Return on Investments',
        imgSrc: investImg3,
        imgAlt: 'investImg3',
        para: 'Investors earn returns on their investment by investing in projects on the CapitaLeta marketplace in three possible ways: cash flow from project operations after all expenses, when the project is refinanced, and distribution when the investment is sold.'
    },
]

export const secondThreeBlockContent = [
    {
        color: 'var(--secondary)',
        haeding: 'Professionally Sourced Projects',
        imgSrc: investImg4,
        imgAlt: 'investImg4',
        para: 'We are committed to bringing institutional-quality real estate and energy investment opportunities for you to access value creation outside the public markets through long-term private markets investments.'
    },
    {
        color: 'var(--primary)',
        haeding: 'Simplified Investing Experience',
        imgSrc: investImg5,
        imgAlt: 'investImg5',
        para: 'We have designed our investment process to make investing simple and focus on delivering the greatest benefit to our investors. Our goal is to provide our investors with access to the best investment opportunities with the least possible effort and time.'
    },
    {
        color: 'var(--secondary)',
        haeding: 'Tracking and Reporting',
        imgSrc: investImg6,
        imgAlt: 'investImg6',
        para: 'Our platform helps investors self-serve through user-friendly tools. With individual investor rooms, investors can easily track their investments. In addition, we monitor the progress of each transaction and report on it on a regular basis.'
    },
]