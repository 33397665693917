import "./CommonFooter.scss";
const CommonFooter = () => {
    return (
        <div className='row common-footer'>
            <div className="col">
                <p>© 2022 CapitaLeta Ltd. All Rights Reserved.</p>
                <h6>Disclaimer</h6>
                <p>By accessing and using this website and any associated CapitaLeta products (the website and products collectively are the “Platform”), you agree to CapitaLeta. (“our,” “we,” or “us”) Terms of Use and Privacy Policy. Please carefully read both documents.

                </p>
                <p>Information on the Platform is not intended to provide legal, accounting, financial or tax advice and should not be relied upon in that regard. This Platform is no substitute for advice from a qualified professional, who should be consulted where expert advice or matter-specific advice is required. While we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the Platform or the information, products, services, or related graphics contained on the Platform for any purpose. Therefore, any reliance you place on such information is strictly at your own risk.</p>
                <p>Through this Platform, you can link other websites which are not under the control of a CapitaLeta. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
                <p>Every effort is made to keep the platform up and running smoothly. However, CapitaLeta takes no responsibility for, and will not be liable for, the Platform being temporarily unavailable due to technical issues beyond our control.</p>
                <p>“IN MAKING AN INVESTMENT DECISION, INVESTORS MUST RELY ON THEIR OWN EXAMINATION OF THE ISSUER AND THE TERMS OF THE OFFERING, INCLUDING THE MERITS AND RISKS INVOLVED. INVESTMENTS IN CAPITALETA LTD. ARE SPECULATIVE, ILLIQUID, AND INVOLVE A HIGH DEGREE OF RISK, INCLUDING THE POSSIBLE LOSS OF YOUR ENTIRE INVESTMENT.”</p>
            </div>
        </div>
    )
}

export default CommonFooter
