import { Link } from 'react-router-dom'
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button1 from '../UI/Button1/Button1'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import './HomeBanner.scss'
const HomeBanner = () => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className='home-banner'>
      <div className="banner-content">
        <h1>Transforming the future <br /> of project investing</h1>
        <p className='home-banner-tag'>Simple, secured and low minimum investments</p>
        <div className='banner-button'>
          <Link to={'/registration'} className='bi-btn becomeInvestor'>
            <Button1 style={{ backgroundColor: 'var(--secondary)' }}>
              Become an Investor <i className='bi bi-arrow-right'></i>
            </Button1>
          </Link>
          <span onClick={() => setShow(true)}>
            <Button1 style={{ backgroundColor: 'var(--primary)' }}>
              Submit your Project <i className='bi bi-arrow-right'></i>
            </Button1>
          </span>

        </div>
        <p className='banner-bottom-text'>Already have an account? <Link to={'/login'}>Sign into the Marketplace.</Link></p>
      </div>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'var(--primary)', fontWeight: '600' }}>Submit a Project</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <Container>
              <Row className='mb-3'>
                <Col>
                  <div class="form-floating">
                    <input type="text" class="form-control" id="floatingProject" placeholder="Project Name" />
                    <label for="floatingProject">Project Name</label>
                  </div>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <div class="form-floating">
                    <textarea class="form-control" id="floatingTextarea2" placeholder="Product/Service" style={{ height: '100px' }}></textarea>
                    <label for="floatingTextarea2">Product/Service</label>
                  </div>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col md={4}>
                  Region
                </Col>
                <Col md={8}>
                  <Form>
                    <Form.Check
                      inline
                      label="Norte"
                      type='radio'
                    />
                    <Form.Check
                      inline
                      label="Centro"
                      type='radio'
                    />
                    <Form.Check
                      inline
                      label="Alentejo"
                      type='radio'
                    />
                    <Form.Check
                      inline
                      label="Lisboa"
                      type='radio'
                    />
                    <Form.Check
                      inline
                      label="Algarve"
                      type='radio'
                    />
                  </Form>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col md={4}>
                  Already have company?
                </Col>
                <Col md={8}>
                  <Form>
                    <Form.Check
                      inline
                      label="Yes"
                      type='radio'
                    />
                    <Form.Check
                      inline
                      label="No"
                      type='radio'
                    />
                  </Form>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col md={4}>
                  Company creation date
                </Col>
                <Col md={8}>
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                          {"<"}
                        </button>
                        <select
                          value={getYear(date)}
                          onChange={({ target: { value } }) => changeYear(value)}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          value={months[getMonth(date)]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                          {">"}
                        </button>
                      </div>
                    )}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    isClearable
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col md={4}>
                  Has PME certification?
                </Col>
                <Col md={8}>
                  <Form>
                    <Form.Check
                      inline
                      label="Yes"
                      type='radio'
                    />
                    <Form.Check
                      inline
                      label="No"
                      type='radio'
                    />
                  </Form>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <div class="form-floating">
                    <input type="text" class="form-control" id="floatingSector" placeholder="Sector" />
                    <label for="floatingSector">Sector (Nº CAE)</label>
                  </div>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <div class="form-floating">
                    <input type="text" class="form-control" id="floatingFunding" placeholder="Funding needs" />
                    <label for="floatingFunding">Funding needs</label>
                  </div>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <div class="form-floating">
                    <textarea class="form-control" id="floatingInformations" placeholder="Other Informations" style={{ height: '100px' }}></textarea>
                    <label for="floatingInformations">Other Informations</label>
                  </div>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <label for="businessPlanFile" class="form-label">Business Plan</label>
                  <input class="form-control" type="file" id="businessPlanFile"></input>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <label for="presentationFile" class="form-label">Presentation / Other info</label>
                  <input class="form-control" type="file" id="presentationFile"></input>
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col>
                  <Form>
                    <Form.Check
                      type='checkbox'
                      label='I authorize the automatic processing of data for the purpose of managing responses to my requests.'
                      style={{ fontSize: '13px' }}
                    />
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form>
                    <Form.Check
                      type='checkbox'
                      label='I authorize the use of my data to receive regular communications from dstgroup.'
                      style={{ fontSize: '13px' }}
                    />
                  </Form>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <span onClick={() => setShow(false)}>
              <Button1 style={{ backgroundColor: 'var(--primary)' }}>
                Close
              </Button1>
            </span>
            <span onClick={() => setShow(false)}>
              <Button1 style={{ backgroundColor: 'var(--secondary)' }}>
                Save Changes
              </Button1>
            </span>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default HomeBanner
