import "./App.scss";
import GuestNav from "./routes/GuestNav";
// import AuthNav from "./routes/AuthNav";
// import Auth from "./services/Auth";
// import DataProvider from "./store/DataProvider";

function App() {
  // const { getDetails } = Auth();
  // if (getDetails() == null) {
  //   return <GuestNav />;
  // }
  return (
    // <DataProvider>
    //   <AuthNav />
    // </DataProvider>
    <GuestNav />
  );
}

export default App;
