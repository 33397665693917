import './InvestorRelation.scss';
import { Button1, PageBanner } from '../../../components'
import { investorRelationBlocks } from '../../../common/InvestorRelationContants';
import { Link } from 'react-router-dom';
import backgroundImage from '../../../assets/images/invrel-banner.jpg'


const InvestorRelation = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='We will present reliable investments, be transparent in our relationship, and be responsive to our commitments'
        para=''
        buttonText=''
      />

      <section>
        <div className="container">
          <h2 className='topicHeading text-center'>REPORTING AND DISTRIBUTION MADE EASY</h2>
          <p className='text-center' style={{ color: 'var(--primary)' }}>CapitaLeta Markets captures real-time data on all investments, including transactions, reports and allocations, creating a centralized, organized and easily accessible database of records for investors.</p>
          <div className="row">
            {
              investorRelationBlocks.map((item, index) => (
                <div className="col-md-6 col-12 ir-block" style={{ backgroundColor: `${item.backgroundColor}`, color: `${item.textColor}` }}>
                  <div className='ir-img-block'><img src={item.imgSrc} alt={item.imgAlt} /></div>
                  <h5>{item.haeding}</h5>
                  <p>{item.para}</p>
                </div>
              ))
            }
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-6 mb-3 p-md-0 d-md-flex">
              <Link to={'/registration'}>
                <Button1 style={{ backgroundColor: 'var(--secondary)' }}>Become a Member <i class="bi bi-chevron-right"></i></Button1>
              </Link>
            </div>
            <div className="col-12 col-md-6 mb-3 p-md-0 d-md-flex">
              <Link to={'/'}>
                <Button1 style={{ backgroundColor: 'var(--secondary)' }}>Contact Investor Relations <i class="bi bi-chevron-right"></i></Button1>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InvestorRelation
