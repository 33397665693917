import './WhyInvest.scss';
import { Button1, PageBanner, StaticThreeBlock } from '../../../components'
import { threeBlockContent } from '../../../common/WhyInvestConstants';
import { Link } from 'react-router-dom';
import backgroundImage from '../../../assets/images/whyinv-banner.jpg'
import whyinvImg4 from '../../../assets/images/whyinv-img4.jpg';
import whyinvImg5 from '../../../assets/images/whyinv-img5.jpg';

const WhyInvest = () => {

  const leftBorderBlock = (haeding, para) => (
    <div className="col-12 col-md-6 d-flex mb-3 mb-md-0">
      <div className="content align-self-stretch">
        <h5>{haeding}</h5>
        <p>{para}</p>
      </div>
    </div>
  )

  const blocks = (color, haeding, image, para) => (
    <div className='col-12 col-md-6 mb-2 mb-lg-0 investor-blocks'>
      <div className="card-title" style={{ backgroundColor: `${color}` }}>
        <h5>{haeding}</h5>
      </div>
      <div className="card-img" style={{ backgroundImage: `url(${image})` }}></div>
      <div className="cards-contents" style={{ borderBottom: `2px solid ${color}` }}>
        <p>{para}</p>
      </div>
    </div>
  )
  
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='Project Investments Should be a Part of Your Portfolio'
        para='Simple, intelligent, frictionless, transparent, and low-cost investment model that offers capital preservation, cash flows, and above-average return on investment'
        buttonText=''
      />

      <section className='sectionPaddingY'>
        <h2 className='topicHeading text-center'>Why Invest with Capitaleta</h2>
        <div className='whyinv-cont1 sectionPaddingY'>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 d-flex mb-3 mb-md-0">
                <div className="content align-self-stretch">
                  <h5>Rigorous Underwriting Process</h5>
                  <p>Investors can earn returns in three ways:</p>
                  <ul>
                    <li>Cash flow after expenses from operations when the project is income-generating.</li>
                    <li>Project Refinancing;when the project is a value-add to in    crease revenue at refinancing.</li>
                    <li>Revenue at the disposition of the project; any appreciation and invested capital is paid to investors.</li>
                  </ul>
                </div>
              </div>
              {
                leftBorderBlock('Simplicity and Accessibility', 'CapitaLeta has simplified and streamlined the process of investing in project opportunities online.The replacement hurdles with automation has added transparency and speed. Within ten minutes, an investor can be fully invested in any of the available projects on CapitaLeta marketplace with as low as $1000.')
              }
            </div>
          </div>
        </div>
      </section>

      <section className='sectionPaddingY'>
        <StaticThreeBlock items={threeBlockContent} />
      </section>

      <section className='whyinv-cont1 sectionPaddingY'>
        <div className="container">
          <div className="row">
            {
              leftBorderBlock('Improved Liquidity', 'Our secondary marketplace, CapitaLeta Exchange, provides a secure place to sell your investments to other investors at a price of your choice, while maintaining regulatory mandate.')
            }
            {
              leftBorderBlock('Professional Management', 'Each project on the CapitaLeta platform is professionally managed on behalf of investors. The Management Company sources the projects, conducts due diligence, underwriting, monitoring, and reporting to CapitaLeta.')
            }
          </div>
        </div>
      </section>

      <section className="sectionPaddingY">
        <div className="container">
          <div className="row whyinv-blocks mb-3">
            {
              blocks('var(--secondary)', 'Portfolio Diversification', whyinvImg4, 'Studies have shown that adding alternative investment to a portfolio adds diversification, which helps reduce risk by allocating assets across various financial instruments with an aim to reduce risk and maximize returns in an investment portfolio.')
            }
            {
              blocks('var(--primary)', 'Inflation Hedge', whyinvImg5, 'Alternative investments in projects opportunities offer similar returns to stocks but with less volatility. More importantly, they exhibit a low correlation to the stock and bond markets and have proven to be an effective inflation hedge.')
            }
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <Link to={'/registration'}>
            <Button1 style={{ backgroundColor: 'var(--secondary)' }}>Become a Member <i class="bi bi-chevron-right"></i></Button1>
          </Link>
        </div>
      </section>

      <section className="leading-project">
        <div className='text-center container'>
          <h4>CapitaLeta is the Leading Project <br /> Investment Platform</h4>
          <p>Thousands of investors use CapitaLeta to invest in real estate, hospitality, energy and infrastructure projects. Our marketplace provide the ease to review offering and find the right projects for your portfolio, and track the performance of your investments.</p>
        </div>
        <div className="whyinv-cont1 sectionPaddingY">
          <div className="container">
            <div className="row">
              {
                leftBorderBlock('Constant Deal Flow', 'In most investments, you are entirely dependent on the decisions of the company’s senior management in terms of the projects they choose to invest in or dispose of. It is not so with Capitaleta. CapitaLeta. you make the choice and have control.')
              }
              {
                leftBorderBlock('Rigorous Underwriting', 'The ownership of each project on CapitaLeta is distributed across a finite number of representative units. each share unit represents ownership. Owners can receive their returns on their investment and vote on project decisions.')
              }
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <Link to={'/'}>
                  <Button1 style={{ color: 'var(--secondary)', fontSize: '1rem', fontWeight: '500' }}>Visit The Marketplace <i class="bi bi-chevron-right"></i></Button1>
                </Link>
              </div>
              <div className="col-12 col-md-6">
                <Link to={'/'}>
                  <Button1 style={{ color: 'var(--secondary)', fontSize: '1rem', fontWeight: '500' }}>Our Transaction Review Process <i class="bi bi-chevron-right"></i></Button1>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WhyInvest
