export const stiContents1 = [
    {
        haeding: 'Register to Access Marketplace',
        para: 'Registration is required to access the Capitaleleta marketplace. By enrolling, you will have access to all project opportunities available on the Marketplace. Every member is entitled to a two-day money-back guarantee on their investment. CapitaLeta delivers payment wallets to registered members on our platform after registration and completion of Know Your Client (KYC).',
        buttonText: '',
        buttonLink: '',
    },
    {
        haeding: 'Fund your Wallet',
        para: 'Members may connect their wallets to their bank accounts after completing the KYC procedure, uploading the needed identification, and setting up the wallet to be ready to take advantage of project opportunity as they become available in the marketplace. Be ready since our project offerings sell out quickly.',
        buttonText: '',
        buttonLink: '',
    },
    {
        haeding: 'Select a project to Invest in',
        para: 'To choose from various projects and investment opportunities, browse the CapitaLeta marketplace. Select a project opportunity you want to invest in and review the due diligence and subscription documentation.',
        buttonText: 'Become an Investor',
        buttonLink: '/registration',
    },
];

export const stiContents2 = [
    {
        haeding: 'COMPLETE AND SIGN INVESTMENT DOCUMENTATION',
        para: 'Determine your project investment amount, and complete, sign investment acknowledgement of the offering memorandum and subscription agreement.',
        buttonText: '',
        buttonLink: '',
    },
    {
        haeding: 'MAKE A PAYMENT FROM YOUR WALLET',
        para: 'Pay for your investment by transferring funds from your wallet to the CapitaLeta account',
        buttonText: '',
        buttonLink: '',
    },
    {
        haeding: 'VIEW AND MANAGE YOUR PORTFOLIO',
        para: 'Investors can log back into their CapitaLeta account on the Marketplace any time after making an investment to view their portfolio and holdings.',
        buttonText: 'Visit The Marketplace',
        buttonLink: '/marketplace',
    },
]