import { Link } from 'react-router-dom';
import { latestNewsAndInsightsIyems } from '../../common/Constants';
import Button1 from '../UI/Button1/Button1';
import './LatestNewsAndInsights.scss';

const LatestNewsAndInsights = () => {
  return (
    <section className="latest-news-and-insights sectionPaddingY">
      <div className="container">
        <div className="row text-center">
          <h6 style={{ color: 'var(--secondary)' }}>News Insights</h6>
          <h2 className="topicHeading">Latest News and Insights</h2>
        </div>
        <div className='row news-blocks'>
          {
            latestNewsAndInsightsIyems.map((item, index) => (
              <div className='col-12 col-md-4 mb-2 mb-md-0' key={index}>
                <div className='news-blocks-inn'>
                  <div className='imgblock'>
                    <img src={item.imgSrc} alt={item.imgAlt} />
                    <Link to={item.detailsLink}>
                      <span className="read-more">
                        <Button1 style={{ backgroundColor: 'var(--secondary)' }}>Read More</Button1>
                      </span>
                    </Link>
                  </div>
                  <div className='news-content'>
                    <h6><span className='cl-user'><i className="bi bi-person"></i> {item.user}</span><span className='cl-date'><i className="bi bi-calendar4-event"></i> {item.date}</span></h6>
                    <Link to={item.detailsLink}>
                      <h5>{item.haedLine}</h5>
                    </Link>
                    <p>{item.smalldesc}</p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default LatestNewsAndInsights
