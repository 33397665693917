import "./LoginRegister.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button1, Card } from "../../../components";
import Auth from "../../../services/Auth";
import { API_URL, apiURL } from "../../../services/Api_endPoint";
import { Spinner } from "react-bootstrap";
import axios from 'axios';
import { signInMutationQuery } from "../../../services/Query";

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [alertshow, showAlert] = useState(false);
  const [message, setMessage] = useState();
  const [variant, setVariant] = useState("danger");
  const { setResponseOnLogin } = Auth();

  const logIn = async () => {
    const response = await axios.post(`${apiURL}/login`, {
      "email": email,
      "password": password
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response) {
      const digiShareResponse = await axios.post(API_URL,
        {
          query: signInMutationQuery,
          variables: {
            "stoId": 0,
            "password": password,
            "email": email
          }
        }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (digiShareResponse.data.hasOwnProperty("errors")) {
        showAlert(true);
        setMessage(digiShareResponse.data.errors[0].message);
      }
      else if (digiShareResponse.data.hasOwnProperty("data")) {
        showAlert(false);
        setResponseOnLogin(response.data.data, digiShareResponse.data.data.signIn);
      }
    }
  }

  const [isClick, setIsClick] = useState(false);


  // Submit the form
  const submitForm = async () => {
    if (!validateForm()) {
      return;
    }
    setIsClick(true)
    logIn();
  }

  useEffect(() => {
    if (alertshow) {
      setIsClick(false)
    }
  }, [alertshow])


  // Form validation
  const validateForm = () => {
    if (email === "") {
      setVariant("danger");
      setMessage("Email is required.");
      showAlert(true);
      return false;
    }
    else if (password === "") {
      setVariant("danger");
      setMessage("Password is required.");
      showAlert(true);
      return false;
    }
    else {
      showAlert(false);
      setMessage("");
      return true;
    }
  }


  return (
    <Card variant={variant} alertshow={alertshow} message={message}>
      <div className="LoginRegister">
        <form>
          <div className="form-floating mb-3">
            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)}></input>
            <label for="floatingInput">Email address</label>
          </div>
          <div className="form-floating">
            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}></input>
            <label for="floatingPassword">Password</label>
          </div>
          <div class="form-check">
            <span>
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
              <label class="form-check-label" for="flexCheckDefault">Remember Me?</label>
            </span>
            <Link to={'/forgot-password'}>Forgot Password?</Link>
          </div>
          <div className="main-btn-block">
            <Button1 style={{ backgroundColor: 'var(--secondary)' }} onClick={submitForm}>
              {
                !isClick &&
                <span>Sign In</span>
              }
              {
                isClick &&
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="ms-2">Loading...</span>
                </>
              }

            </Button1>
            <div className="registration-block">Not registered yet? <Link to={'/registration'}>Create an account.</Link></div>
          </div>
        </form>

      </div>
    </Card>
  )
}

export default Login
