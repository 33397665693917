import './InvestmentApproach.scss'
import { Button1, PageBanner } from '../../../components';
import backgroundImage from '../../../assets/images/oia-banner.jpg'
import oiaImg from '../../../assets/images/oia-img.png';
import { Link } from 'react-router-dom';

const InvestmentApproach = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='WE ARE THE SMARTER ALTERNATIVE FOR REDUCED RISK AND IMPROVED STABILITY'
        para='Our investment process is research-driven by the philosophy of investing in projects that are projected to earn a strong, stable income, secured by high-quality real estate, energy and infrastructure projects while paying a price that does not fully reflect long-term intrinsic value.'
        buttonText=''
      />
      <section className='sectionPaddingY'>
        <div className="container">
          <div className="row">
            <h2 className='topicHeading text-center'>Investment Approach</h2>
          </div>
          <div className="row p-5 investmentApproach">
            <div className="col-12 col-md-7 mb-3 mb-md-0 d-flex align-items-center">
              <div>
                <p>We focus on assets driven by robust underlying fundamentals, pivoting off of their
                  lows and potentially offering acquisition opportunities at relative values. As 2022
                  wraps up and welcomes 2023, we view the real estate and energy markets as more
                  balanced and better positioned for broader, though moderated, growth across the
                  board.</p>
                <div className='btn-block d-md-flex'>
                  <Link to={'/'}>
                    <Button1 style={{ backgroundColor: 'var(--secondary)', textAlign: 'center' }}>Download Our Investment Approach <i class="bi bi-chevron-right"></i></Button1>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <img src={oiaImg} alt="oiaImg" />
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

export default InvestmentApproach
