//sidenav
export const kycSidenavItem = [
    {
        name: 'Personal Information',
        id: 'personalInformation'
    },
    {
        name: 'Address',
        id: 'address'
    },
    {
        name: 'Investor Questionaire',
        id: 'investorQuestionaire'
    },
    {
        name: 'Type of Investor',
        id: 'typeOfInvestor'
    },
    {
        name: 'Complete Setup',
        id: 'completeSetup'
    },
    {
        name: 'Document',
        id: 'document'
    },
    // {
    //     name: 'Review & Submit',
    //     id: 'reviewSubmit'
    // }
];

//Investor Questionaire
export const investmentKnowledge = [
    {
        id: 'investment_knowledge1',
        value: 'Investment Advisor',
        desc: 'I have an investment advisor that I pay to manage my portfolio.'
    },
    {
        id: 'investment_knowledge2',
        value: 'Self-Educated',
        desc: 'I read books and articles, picking investments based on my own in-depth analysis.'
    },
    {
        id: 'investment_knowledge3',
        value: 'Friends and family',
        desc: 'I rely on the advice of friends and family that I trust.'
    },
    {
        id: 'investment_knowledge4',
        value: "I don't seek advice",
        desc: "Historically, I don't invest outside of my employer-based plan, so I own whatever they’ve selected for me."
    },
]

export const investmentExperience = [
    {
        id: 'investment_experience1',
        value: 'Novice',
        desc: 'I am new to investing.'
    },
    {
        id: 'investment_experience2',
        value: 'Beginner',
        desc: 'I read books and articles, picking investments based on my own in-depth analysis.'
    },
    {
        id: 'investment_experience3',
        value: 'Competent',
        desc: "I'm relatively experienced when it comes to investing in the stock market and may be familiar with real eprovince investing."
    },
    {
        id: 'investment_experience4',
        value: 'Proficient',
        desc: "I invest in a wide variety of stocks and bonds, this can include real eprovince investing."
    },
    {
        id: 'investment_experience5',
        value: 'Expert',
        desc: "I have experience investing in private real eprovince deals or have owned my own investment properties."
    },
]

export const investmentMotivation = [
    {
        id: 'investment_motivation1',
        value: 'Diversification',
        desc: "I'm looking to diversify outside of just the stock market."
    },
    {
        id: 'investment_motivation2',
        value: 'Income',
        desc: "I'm interested in generating consistent cash flow."
    },
    {
        id: 'investment_motivation3',
        value: 'Retirement',
        desc: "I want to invest towards retirement."
    },
    {
        id: 'investment_motivation4',
        value: 'Major purchase',
        desc: "I'm working towards a large purchase like home ownership."
    },
    {
        id: 'investment_motivation5',
        value: 'Education',
        desc: "I want to invest towards my child's education."
    },
    {
        id: 'investment_motivation6',
        value: 'Building Wealth',
        desc: "I'm generally interested in building wealth."
    }
]

export const investmentPlanning = [
    {
        id: 'investment_planning1',
        value: 'Less than $5000',
        desc: ''
    },
    {
        id: 'investment_planning2',
        value: '$5,000 - $10,000',
        desc: ''
    },
    {
        id: 'investment_planning3',
        value: '$11,000 - $30,000',
        desc: ''
    },
    {
        id: 'investment_planning4',
        value: '$31,000 - $150,000',
        desc: ''
    },
    {
        id: 'investment_planning5',
        value: 'Above $150,000',
        desc: ''
    },
]

export const lengthofInvestment = [
    {
        id: 'length_of_investment1',
        value: 'Less than 3 years',
        desc: ''
    },
    {
        id: 'length_of_investment2',
        value: '3-5 years',
        desc: ''
    },
    {
        id: 'length_of_investment3',
        value: '6-10 years',
        desc: ''
    },
    {
        id: 'length_of_investment4',
        value: 'More than 10 years',
        desc: ''
    },

]

export const incomeLevel = [
    {
        id: 'income_level1',
        value: 'Less than $70,000',
        desc: ''
    },
    {
        id: 'income_level2',
        value: '$71,000 - $150,000',
        desc: ''
    },
    {
        id: 'income_level3',
        value: '$151,000 - $250,000',
        desc: ''
    },
    {
        id: 'income_level4',
        value: '$251,000 - $300,000',
        desc: ''
    },
    {
        id: 'income_level5',
        value: 'Over $301,000',
        desc: ''
    },
]
export const householdIncome = [
    {
        id: 'household_income1',
        value: 'Less than $100,000',
        desc: ''
    },
    {
        id: 'household_income2',
        value: 'Between $100,000 and $199,999',
        desc: ''
    },
    {
        id: 'household_income3',
        value: 'Between $200,000 and $299,999',
        desc: ''
    },
    {
        id: 'household_income4',
        value: 'Between $300,000 and $499,999',
        desc: ''
    },
    {
        id: 'household_income5',
        value: '$500,000 or more',
        desc: ''
    }
]

// TYPE OF INVESTOR
// export const typeOfInvestor =[
//     {
//         id: 'typeOfInvestor1',
//         value: 'Individual',
//         desc: 'I am investing on behalf of myself, my family of other.'
//     },
//     {
//         id: 'typeOfInvestor2',
//         value: 'Entity',
//         desc: 'I am investing for my company, corporation, trust or partnership.'
//     },
//     {
//         id: 'typeOfInvestor3',
//         value: 'Investment Representative / Advisor',
//         desc: 'I am a Representative or Company investing on behalf of our clients (such as investment funds etc).'
//     },
// ]

// export const individual_type=[
//     {
//         id: 'individual_type1',
//         value: 'Non-Eligible Investor'
//     },
//     {
//         id: 'individual_type2',
//         value: 'Eligible Investor'
//     },
//     {
//         id: 'individual_type3',
//         value: 'Accredited Investor'
//     },
//     {
//         id: 'individual_type4',
//         value: 'Family, Friends and Business Associates'
//     },
// ]

export const entity_type = [
    {
        id: 'entity_type1',
        value: "Private Corporation",
    },
    {
        id: 'entity_type2',
        value: "Public Corporation",
    },
    {
        id: 'entity_type3',
        value: "Partnerships and Others",
    },
    {
        id: 'entity_type4',
        value: "Financial Institution",
    },
    {
        id: 'entity_type5',
        value: "Pension and Investments Funds",
    },
    {
        id: 'entity_type6',
        value: "Government",
    },
    {
        id: 'entity_type7',
        value: "Trust",
    },
    {
        id: 'entity_type8',
        value: "Registered Charity",
    },

]