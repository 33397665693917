import './InvestorsCommitment.scss';
import { PageBanner, StaticThreeBlock } from '../../../components'
import backgroundImage from '../../../assets/images/oic-banner.jpg'
import { threeBlockContent } from '../../../common/InvestorsCommitmentConstants';
import { Link } from 'react-router-dom';

const InvestorsCommitment = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='We are Committed to Excellence'
        para='Capitaleta aims to transform what was formally inefficient into efficiency.
        Change the opacity to become transparent and level the playing field for
        every investor.'
        buttonText=''
      />
      <section>
        <div className="container-fluid">
          <h2 className='topicHeading text-center text-uppercase'>Investor Commitment</h2>
          <StaticThreeBlock items={threeBlockContent} />
        </div >
      </section>
      <section className='sectionPaddingY'>
        <div style={{ backgroundColor: 'var(--fifthColor)' }}>
          <div className='py-4'>
            <div className='leftBorderBlock container-inn'>
              <h5 className='topicHeading'>Project Sourcing and Risk Underwriting</h5>
              <p><span className='mb-2'>Capitaleta is actively looking for project opportunities with experienced and innovative project sponsors who invite private and corporate investors to participate in their projects.</span>
              
                Our Sector Operation Team with established industry and due diligence Our Sector Operation Team has a proven industry and due diligence process to evaluate every potential transaction, sponsor, management team and track record.
                
                We also conduct a detailed analysis of the project documents, evaluating all plans and market assumptions as carefully as possible to determine their suitability for our marketplace. We analyze each project opportunity against our established <Link to={'/project-review'}>Project Review Process
                </Link> without compromising the speed with which we attract new sponsors and start new transactions.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='sectionPaddingY'>
        <div style={{ backgroundColor: 'var(--fourthColor)' }}>
          <div className='py-4'>
            <div className='rightBorderBlock container-inn'>
              <h5 className='topicHeading text-end'>Simplified Investing Experience</h5>
              <p>We have designed our investment process to make investing simple and focus on delivering the greatest benefit to our investors. Our goal is to provide our investors with access to the best investment opportunities with the least possible effort and time.

Our aim is to give everyone the opportunity to buy shares for as little as $1,000 and start building capital or earning monthly income. If our allocation in the transaction does not satisfy all interested investors, we will clarify your place in the process. Capitaleta investments are made on a first-come, first-served basis.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='sectionPaddingY'>
        <div style={{ backgroundColor: 'var(--fifthColor)' }}>
          <div className='py-4'>
            <div className='leftBorderBlock container-inn'>
              <h5 className='topicHeading'>Project Sourcing and Risk Underwriting</h5>
              <p>Capitaleta is actively looking for project opportunities with experienced and innovative project sponsors who invite private and corporate investors to participate in their projects.
                Our Sector Operation Team with established industry and due diligence Our Sector Operation Team has a proven industry and due diligence process to evaluate every potential transaction, sponsor, management team and track record.
                We also conduct a detailed analysis of the project documents, evaluating all plans and market assumptions as carefully as possible to determine their suitability for our marketplace. We analyze each project opportunity against our established <Link to={'/project-review'}>Project Review Process
                </Link> without compromising the speed with which we attract new sponsors and start new transactions.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='sectionPaddingY'>
        <div style={{ backgroundColor: 'var(--fourthColor)' }}>
          <div className='py-4'>
            <div className='rightBorderBlock container-inn'>
              <h5 className='topicHeading text-end'>Project Sourcing and Risk Underwriting</h5>
              <p>Capitaleta is actively looking for project opportunities with experienced and innovative project sponsors who invite private and corporate investors to participate in their projects.
                Our Sector Operation Team with established industry and due diligence Our Sector Operation Team has a proven industry and due diligence process to evaluate every potential transaction, sponsor, management team and track record.
                We also conduct a detailed analysis of the project documents, evaluating all plans and market assumptions as carefully as possible to determine their suitability for our marketplace. We analyze each project opportunity against our established <Link to={'/project-review'}>Project Review Process
                </Link> without compromising the speed with which we attract new sponsors and start new transactions.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InvestorsCommitment
