import axios from 'axios';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
export default function Auth() {
    const http = axios.create({
        baseURL: "https://api.capitaleta.com/gql/",
        headers: {
            "Content-type": "application/json"
        }
    });

    const navigate = useNavigate();

    const onSuccessLogin = (userDetails, digiShareResponseToken) => {
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
        sessionStorage.setItem('digiShareResponseToken', JSON.stringify(digiShareResponseToken));
        navigate('/marketplace');
    }

    const getDigiShareResponseToken = useCallback(() => {
        const digiShareResponseTokenString = sessionStorage.getItem('digiShareResponseToken');
        const digiShareResponseToken = JSON.parse(digiShareResponseTokenString);
        return digiShareResponseToken;
    },[])

    const getToken = () => {
        const tokenString = sessionStorage.getItem('userDetails');
        const userToken = JSON.parse(tokenString);
        return userToken.token;
    }
    const getApiToken = () => {
        const jsonStr = sessionStorage.getItem('userDetails');
        const userDetails = JSON.parse(jsonStr);
        return userDetails.apitoken;
    }
    const getUserDetails = () => {
        const tokenString = sessionStorage.getItem('userDetails');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }
    const logout = () => {
        sessionStorage.clear();
        navigate('/login');
    }

    const APIToken = () => {
        const tokenString = "eyJhbGciOiJIUzI1NiJ9.eyJJRCI6MCwic3RvSUQiOjAsInJvbGUiOjN9.Ph-BC8NhMmRTbXskKtz2Mzw7JIy0ZoNeNDexKBT3xN4";
        return tokenString;
    }
    return {
        http,
        APIToken,
        logout,
        token: getToken,
        digiShareResponseToken: getDigiShareResponseToken,
        setResponseOnLogin: onSuccessLogin,
        apiToken: getApiToken,
        getDetails: getUserDetails,
    }
}