import { PageBanner } from '../../../components'
import backgroundImage from '../../../assets/images/sponsors-banner.jpg'

const Sponsors = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='Frequently Asked Questions'
        para='If you are interested to learn more about the capitaleta marketplace or have any questions about the process, you can check out these faqs or contact us.'
        buttonText=''
      />
    </>
  )
}

export default Sponsors
