import { PageBanner } from '../../../components'
import backgroundImage from '../../../assets/images/crp-banner.jpg'

const CapitalRaising = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='The Smarter Way to Raise Capital for Bankable Projects'
        para='On behalf of project sponsors, we leverage thousands of investors in our network to raise capital for their projects, allowing them to spend more time on the project rather than looking for financing.'
        buttonText=''
      />
    </>
  )
}

export default CapitalRaising
