import irIcon1 from '../assets/images/internal.png';
import irIcon2 from '../assets/images/upload-file.png';
import irIcon3 from '../assets/images/invrel-icon3.png';
import irIcon4 from '../assets/images/document.png';
import irIcon5 from '../assets/images/tax.png';
import irIcon6 from '../assets/images/satisfaction.png';

export const investorRelationBlocks = [
    {
        backgroundColor: 'var(--secondary)',
        textColor: 'white',
        imgSrc: irIcon1,
        imgAlt: 'irIcon1',
        haeding: 'Comprehensive communication',
        para: 'Our integrated communication tools make it easy to prepare reports, attach investment documents, send bulk emails to target investors and review previous communications with investors. Our powerful technology platform allows us to create, edit and send an email to our entire investor base with just a few clicks.'
    },
    {
        backgroundColor: 'var(--primary)',
        textColor: 'white',
        imgSrc: irIcon2,
        imgAlt: 'irIcon2',
        haeding: 'Reporting',
        para: 'Project sponsors must provide the management company with quarterly updates on how your project is performing against the plan. Nevertheless, Capitaleta will provide investors with constant project updates on all project inspections. Reports will be uploaded directly to our communication system, ensuring that any investor can refer to the document quickly and securely.'
    },
    {
        backgroundColor: '',
        textColor: 'var(--primary)',
        imgSrc: irIcon3,
        imgAlt: 'irIcon3',
        haeding: 'Posting and Paying Distributions',
        para: 'We calculate and distribute payments through our communication system so that investors can see the results of their investments and receive any distribution in their wallets. By providing investors with a payment wallet during registration, we make payments and distributions from and to investors easy.'
    },
    {
        backgroundColor: '',
        textColor: 'var(--primary)',
        imgSrc: irIcon4,
        imgAlt: 'irIcon4',
        haeding: 'Response to Enquiries',
        para: 'We are committed to transparent and consistent communication to ensure a positive investor experience. Our team responds to investors\' questions within 24 working hours.'
    },
    {
        backgroundColor: 'var(--primary)',
        textColor: 'white',
        imgSrc: irIcon5,
        imgAlt: 'irIcon5',
        haeding: 'Annual Tax Documents',
        para: 'We provide each investor with the correct tax documents so that they can file their tax returns on time. Our system can match tax documents for each investment entity, ensuring secure document sharing.'
    },
    {
        backgroundColor: 'var(--secondary)',
        textColor: 'white',
        imgSrc: irIcon6,
        imgAlt: 'irIcon6',
        haeding: 'Investor satisfaction',
        para: 'Overall, CapitaLeta\'s technology platform helps investors self-serve through user-friendly tools. With their own personal Investor Room, investors can easily track their investments, create reports, view documents, submit offers, and view communications and distribution in our secure online portal.'
    }
]