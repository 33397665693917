import "./LoginRegister.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button1, Card } from "../../../components";
import axios from 'axios';
import { apiURL, API_URL } from "../../../services/Api_endPoint";
import { signUpMutationQuery } from "../../../services/Query";


const Registration = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("danger");
  const [alertshow, showAlert] = useState(false);

  let marketplaceIdUser = 0;
  // let userDetails = ;
  // Add a new user to the database
  const setProfile = async () => {
    const response = await axios.post(`${apiURL}/register`, {
      "first_name": firstname,
      "last_name": lastname,
      "email": email,
      "password": password
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log(response)
  }

  // Add a new user to the DigishareMarketplace  
  const signUpDigishareMarketplace = async () => {
    const response = await axios.post(API_URL,
      {
        query: signUpMutationQuery,
        variables: {
          "data": {
            "firstName": firstname,
            "lastName": lastname,
            "password": password,
            "stoID": 0,
            "email": email,
            "investorType": 0
          }
        }
      }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.data.hasOwnProperty("errors")) {
      setVariant("danger");
      showAlert(true);
      setMessage(response.data.errors[0].message);
    }
    else if (response.data.hasOwnProperty("data")) {
      marketplaceIdUser = response.data.data.signUp;
      console.log(marketplaceIdUser);
      setProfile();
      setVariant("success");
      setMessage("Registration succesfull. Please verify your email before signing in.");
      showAlert(true);
    }
  }


  // Submit the form
  const submitForm = async () => {
    if (!validateForm()) {
      return;
    }
    signUpDigishareMarketplace();
  }

  // Form validation
  const validateForm = () => {
    if (firstname === "") {
      setVariant("danger");
      setMessage("Firstname is required.")
      showAlert(true);
      return false;
    }
    else if (lastname === "") {
      setVariant("danger");
      setMessage("Lastname is required.");
      showAlert(true);
      return false;
    }
    else if (email === "") {
      setVariant("danger");
      setMessage("Email is required.");
      showAlert(true);
      return false;
    }
    else if (password === "") {
      setVariant("danger");
      setMessage("Password is required.");
      showAlert(true);
      return false;
    }
    else {
      showAlert(false);
      setMessage("");
      return true;
    }
  }

  //Set email in sessionStorage
  useEffect(() => {
    sessionStorage.setItem("userEmail", email);
  }, [email])

  return (
    <Card variant={variant} alertshow={alertshow} message={message}>
      <div className="LoginRegister">
        <form>
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingFirstname" placeholder="First Name" value={firstname} onChange={e => setFirstname(e.target.value)}></input>
            <label for="floatingFirstname">Firstname</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingLastname" placeholder="Last Name" value={lastname} onChange={e => setLastname(e.target.value)}></input>
            <label for="floatingLastname">Lastname</label>
          </div>
          <div className="form-floating mb-3">
            <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)}></input>
            <label for="floatingEmail">Email address</label>
          </div>
          <div className="form-floating">
            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}></input>
            <label for="floatingPassword">Password</label>
          </div>
          <div className="main-btn-block pt-3">
            <Button1 style={{ backgroundColor: 'var(--secondary)' }} onClick={submitForm} >Register</Button1>
            <div className="registration-block">Already have account? <Link to={'/login'}>Sign in</Link></div>
          </div>
        </form>

      </div>
    </Card>
  )
}

export default Registration
