import { Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { About, CapitalRaising, Careers, Contact, Disclosures, EmailVerification, EventsWebiners, FAQ, Home, HowitWorks, Insights, InsightsDetails, IntegratedESG, Investors, InvestorRelation, Login, OurImpact, InvestmentApproach, InvestorsCommitment, Philantropy, PostApproval, PrivacyPolicy, ProjectDetails, ProjectEvaluation, ProjectReview, Registration, RequestInformation, Sponsors, StepsToInvest, TermsOfUse, WhyInvest, MarketPlaceGuest } from "../pages";
import { GuestFooter, GuestHeader } from "../_layouts";

const GuestNav = () => {
  return (
    <>
      <GuestHeader />
      <Routes>
        <Route path="about" element={<About />} />
        <Route path="capital-raising" element={<CapitalRaising />} />
        <Route path="careers" element={<Careers />} />
        <Route path="contact" element={<Contact />} />
        <Route path="disclosures" element={<Disclosures />} />
        <Route path="events-webiners" element={<EventsWebiners />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="marketplace" element={<MarketPlaceGuest />} />
        <Route path="/" element={<Home />} />
        <Route path="how-it-works" element={<HowitWorks />} />
        <Route exact path="insights" element={<Insights />} />
        <Route path="insights/details" element={<InsightsDetails />} />
        <Route path="integrated-esg" element={<IntegratedESG />} />
        <Route path="investor-relation" element={<InvestorRelation />} />
        <Route path="investors" element={<Investors />} />
        <Route path="OurImpact" element={<OurImpact />} />
        <Route path="investment-approach" element={<InvestmentApproach />} />
        <Route path="investors-commitment" element={<InvestorsCommitment />} />
        <Route path="philantropy" element={<Philantropy />} />
        <Route path="post-approval" element={<PostApproval />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="project-details" element={<ProjectDetails />} />
        <Route path="project-evaluation" element={<ProjectEvaluation />} />
        <Route path="project-review" element={<ProjectReview />} />
        <Route path="request-information" element={<RequestInformation />} />
        <Route path="sponsors" element={<Sponsors />} />
        <Route path="steps-to-invest" element={<StepsToInvest />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="why-invest" element={<WhyInvest />} />
        <Route path="login" element={<Login />} />
        <Route path="registration" element={<Registration />} />
        <Route path="email-verification/:id" element={<EmailVerification />} />
      </Routes>
      <GuestFooter />
      <div>
        <ScrollToTop smooth />
      </div>
    </>
  )
}

export default GuestNav
