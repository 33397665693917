import React from 'react'
import Marketplace from '../../components/Marketplace/Marketplace'

const MarketPlace = () => {
    return (
        <>
            <Marketplace />
        </>
    )
}

export default MarketPlace
