import React from 'react'

const Philantropy = () => {
  return (
    <div>
      
    </div>
  )
}

export default Philantropy
