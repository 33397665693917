import './Header.scss';
import { NavLink } from "react-router-dom";
import { Button1 } from "../../components";
import { guestMenus } from "../../common/Constants";
import { useState } from "react";
import Logo from '../../assets/images/capitaletaLogo.png';


const GuestHeader = () => {
  const [click, setClick] = useState(false)
  const [subMenuClick, setSubMenuClick] = useState(false)

  return (
    <header className="sticky-top boxShadow">
      <div className="main-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 p-0 d-flex align-items-center">
              <NavLink to='/' className="siteLogo" onClick={() => setClick(false)}>
                <img src={Logo} alt="Logo" />
              </NavLink>
            </div>
            <div className='col-9 p-0 header-right'>
              <button className='btn btnToggle menu-icon' onClick={() => setClick(!click)}>
                <i className={click ? "bi bi-x-square" : "bi bi-list"}></i>
              </button>
              <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                {
                  guestMenus.map((item) => (
                    <li key={item.name} onMouseEnter={() => setSubMenuClick(false)} onMouseLeave={() => setSubMenuClick(true)}>
                      <NavLink to={item.link}>{item.name} {item.icon ? <span className='arrow'>{item.icon}</span> : ""}</NavLink>
                      {
                        item.subMenu &&
                        <ul className={subMenuClick ? 'dropdown-menu clicked' : 'dropdown-menu'} onClick={() => setSubMenuClick(!subMenuClick)}>
                          {
                            item.subMenu.map((item) => (
                              <li key={item.name} onClick={() => setSubMenuClick(false)}>
                                <NavLink to={item.link} onClick={() => setClick(false)}>{item.name}</NavLink>
                              </li>
                            ))
                          }
                        </ul>
                      }
                    </li>
                  )
                  )
                }
                <li className="ms-3">
                  <NavLink to="/registration" onClick={() => setClick(false)} className="p-0">
                    <Button1 style={{ backgroundColor: 'var(--secondary)' }}>
                      Join Capitaleta
                    </Button1>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div >
      </div>
    </header >
  )
}

export default GuestHeader
