import tabIcon1 from '../assets/images/general.png';
import tabIcon2 from '../assets/images/Investment.png';
import tabIcon3 from '../assets/images/Investors.png';
import tabIcon4 from '../assets/images/sponsors.png';
import tabIcon5 from '../assets/images/membership.png';

export const tabs = [
    {
        imgSrc: tabIcon1,
        imgAlt: 'tabIcon1',
        text: 'General'
    },
    {
        imgSrc: tabIcon2,
        imgAlt: 'tabIcon2',
        text: 'Investments'
    },
    {
        imgSrc: tabIcon3,
        imgAlt: 'tabIcon3',
        text: 'Investors'
    },
    {
        imgSrc: tabIcon4,
        imgAlt: 'tabIcon4',
        text: 'Sponsors'
    },
    {
        imgSrc: tabIcon5,
        imgAlt: 'tabIcon5',
        text: 'Membership'
    },
];

