import './FAQ.scss';
import { PageBanner } from '../../../components'
import backgroundImage from '../../../assets/images/banner-faq.jpg'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { tabs } from '../../../common/FAQConstants';
import { Link } from 'react-router-dom';

const FAQ = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='Frequently Asked Questions'
        para='If you are interested to learn more about the capitaleta marketplace or have any questions about the process, you can check out these faqs or contact us.'
        buttonText=''
      />
      <div className="mainContainer">
        <div className='container sectionPaddingY'>
          <div className='faq_details'>
            <div className='custom-tabs'>
              <Tabs>
                <TabList>
                  {
                    tabs.map(item => (
                      <Tab key={item.text}><img src={item.imgSrc} alt={item.imgAlt}></img><p>{item.text}</p></Tab>
                    ))
                  }
                </TabList>
                <TabPanel className="cl-tabpanel">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What's CapitaLeta?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>CapitaLeta offers simplified investments in real estate, energy, hospitality, and infrastructure projects. Our mission is to simplify access to project investment opportunities sourced by our Sector Operation Team (SOT), which consists of specialists with an average of over 15 years of experience.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          How does the process work?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <h6>STEP 1: PROJECT ONBOARDING</h6>
                        <ol>
                          <li>CapitaLeta identifies project opportunity and completes due diligence and underwriting process Investment decisions are made collectively by our Sector Operating Team (SOT) and our Investment Committee, which has a track record of investing in the sector and managing such projects. All opportunities go through our propriety underwriting processes.</li>
                          <li>Complete Project Bankability Analysis and 3-Step Underwriting Upon completing the project underwriting and approval by our Investment Committee, we enter into investment agreement with the project sponsor on behalf of our Limited Partners. </li>
                          <li>CapitaLeta fractionizes the investment on our proprietary platform The project investment opportunity is broken out into increments valued at $1 per unit.</li>
                          <li>CapitaLeta presents investment opportunities to our members at $1per unit The units of the project investment opportunity are listed for sale on our platform. Partners can decide how much they want to invest, ranging from $1 to the maximum permitted per project.</li>
                        </ol>
                        <h6>STEP 2: BECOMING AN INVESTOR</h6>
                        <ol>
                          <li>Become a member and get a wallet Membership on CapitaLeta gives you access to every project offered for investment on our platform. You have our 60 days moneyback guarantee on any investment you make. CapitaLeta provides all members with a wallet. </li>
                          <li>Fund your wallet to invest Connect your wallet to your bank account, so you are ready to invest in project opportunities when they arrive on the platform. Our projects sell out fast!</li>
                          <li>Choose a project opportunity to invest Browse our platform to find available project investment opportunities—review due diligence documents on each project opportunity on the platform and the Offering Memorandum (OM).</li>
                          <li>Sign and invest <br />Sign the OM acknowledgement and Subscription Agreement. Decide on the amount to invest up to a maximum permitted per project by simply moving funds from your wallet to the CapitaLeta account.</li>
                          <li>Congratulations! View your portfolio <br />Log back into your account on CapitaLeta Marketplace anytime to view your portfolio and holdings.</li>
                        </ol>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          How does CapitaLeta decide what investment opportunity to present to investors?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>CapitaLeta does not allow project sponsors to list their projects and raise capital directly on our marketplace. Project sponsors seeking to raise capital present their projects and entity to undergo due diligence and our 3-Step underwriting processes. Our Sector Operation Team (SOT), with extensive experience in real estate, hospitality, energy, and infrastructure, ensures that projects presented to investors through our marketplace meet our minimal economic metrics and pass our 3-Steps underwriting process.
                          Additionally, CapitaLeta works with the sponsors on ways to structure the terms of a transaction in order to be attractive to potential investors. Only after a potential transaction passes due diligence and offers an attractive investment profile, will it be accepted and officially offered on CapitaLeta Marketplace.
                          Our bankability review and analysis, project due diligence, and 3-Step underwriting process are major value additions to the projects and advantages to investors. They save time to mitigate risks and present opportunities to profitability metrics.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Which project sectors investment opportunity does the CapitaLeta platform present?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>CapitaLeta presents projects in real estate, hospitality, energy, and infrastructure. CapitaLeta has commenced its operations with residential real estate due to its attractiveness to local and international investors. The next step will be commercial real estate, hotels and office complexes to generate a more diverse set of offerings to our members. As we scale, we will include the renewable energy and infrastructure sectors.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Who holds the deed to the project?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>It is currently legally impossible to ascertain project ownership directly through fractionalization. CapitaLeta fractional interest is in a Limited Partnership or Incorporated Entity that is the sole owner of the asset. Each asset will be owned by one single legal entity. A legal entity holds the deed to each project. Purchasing project investment from legal entities that own one single deed of a project is purchasing your share of that deed.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          How do we ensure each legal entity owns the deed on its project?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>The purchase and sale transaction and paperwork between the project sponsor and the Limited Partnership are conducted by attorneys we rely on. The paperwork includes the transfer of the deed, and a filing in the appropriate jurisdiction is made. The paperwork is then filed with an independent escrow agent, which holds the papers on behalf of the legal entity.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          How do we conduct due diligence on projects?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>We request from the project sponsor and perform a detailed review of all relevant project documents, such as project information, business plans, location details, copy of permits, competitors analysis, local geographic area demographics, financial information and projections, sponsor / management bios and any other documents we deem necessary.
                        </p>
                        <p>Copy of these project documents are included on the project page of the CapitaLeta MarketPlace for investors' review, so that they can make informed investment decision.<br />
                          In addition, each offering has a private placement memorandum. We encourage potential investors to read and analyze all the materials available.<br />
                          If you have additional questions, you can send an email to: support@Capitaleta.com for more information.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Who manages CapitaLeta projects?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>While the project sponsor is ultimately responsible for managing and delivering the projects that back the investments, CapitaLeta outsources Asset Management to professionals that carefully monitor and report on each of the projects owned by our investors.</p>
                        <p>CapitaLeta is under service level agreement with Capitaleta for project sourcing, undertaking due diligence, project economic, financial and Environmental, Social, and Governance (ESG) underwriting, project monitoring and reporting to Capitaleta.</p>
                        <p>CapitaLeta provides value-added services to investors and project sponsors. However, we continuously monitor all aspects of our projects, from construction, lease and lease rates to physical conditions, renovations, maintenance, code compliance, and even landscaping improvements.</p>

                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What happens if CapitaLeta goes out of business?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>CapitaLeta is not a limited liability company that owns the asset you are investing in. Each Company would remain a separate legal entity for project holding, tax, accounting, liability, and member ownership purposes.
                          Regardless of what happens to CapitaLeta, the assets and project investors of the Company would remain independent and intact. Investors, therefore, remain the owner of the projects. If there is a liquidation of CapitaLeta, a new Managing Member would be appointed. This could be a lawyer temporarily, an accountant, or even the biggest Holder.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
                <TabPanel className="cl-tabpanel">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What are the benefits of investing directly in projects?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>In addition to one’s primary projects, for example, in real estate – primary residence, investors typically hold a portfolio of stocks, mutual funds, ETFs, bonds, etc.  Studies have shown that adding alternative investment to a portfolio adds diversification, which helps reduce risk by allocating assets across various financial instruments, industries, and other categories. Diversification aims to maximize returns by investing in different areas that react differently to the same event. Alternative investments in projects offer similar returns to stocks but with less volatility.  More importantly, they exhibit a low correlation to the stock and bond markets and have proven to be an effective inflation hedge.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What is the benefit of investing directly in a project versus investing in publicly traded stock and bond issued by an alternative asset management company?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>The publicly traded alternative asset management company typically focuses on a single project type with various exposures across geographies and markets, some of which may not be favorable to some investors depending on the economic and social environment.  Moreover, as a project investor in the stocks and bonds of alternative asset management companies, you are entirely dependent on the decisions of the company’s senior management in terms of the projects they choose to invest in or dispose of. It is not so with Capitaleta. By investing directly in Capitaleta projects, you can select the project type(s) and exact locations that you feel will perform the best.  Capitaleta provides you with the option to invest in what type of project you like, in the area you want, when you like, and with who you care about. Above all else, you have complete control, subject to any holding period requirements, of when you would like to liquidate your investment.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Can I make investments through an entity?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>Yes, entities such as Corporations, Trusts, and Partnerships can invest in Capitaleta Investment opportunities. Entity investors must be considered “Accredited” investors to participate in our offerings.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
                <TabPanel className="cl-tabpanel">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What is an “Accredited Investor”?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <h6>Individual: <br />An individual is considered an “Accredited Investor” if at least one of the following criteria is met.</h6>
                        <ul>
                          <li>Your net income before taxes was more than $200,000 in each of the two most recent calendar years, and you expect it to be more than $200,000 in the current calendar year.</li>
                          <li>Your net income before taxes combined with your spouse’s was more than $300,000 in each of the two most recent calendar years, and you expect your combined net income before taxes to be more than $300,000 in the current calendar year.</li>
                          <li>Either alone or with your spouse, you own more than $1 million in cash and securities after subtracting any debt related to the money and securities.</li>
                          <li>Either alone or with your spouse, you have net assets worth more than $5 million. (Your net assets are your total assets (including real estate) minus your total debt.)</li>
                          <li>A person or company registered under the securities legislation of a province or territory of Canada as an adviser or dealer.</li>
                        </ul>
                        <h6>Corporations and Partnerships:<br />
                          An entity is accredited if it meets any one of the following criteria:</h6>
                        <ul>
                          <li>Possesses investments of more than $5 million and was NOT formed for the specific purposes of acquiring the investment.</li>
                          <li>Equity owners of the entity are accredited, individual investors.</li>
                          <li>A person or company registered under the securities legislation of a province or territory of Canada as an adviser or dealer.</li>
                        </ul>
                        <h6>Trusts<br />
                          A trust is accredited if it meets any one of the following criteria:</h6>
                        <ul>
                          <li>Possesses investments of more than $5 million and was NOT formed for the specific purpose of acquiring the investment and is directed by a sophisticated person.</li>
                          <li>The trust utilizes a bank, insurance company, registered investment company, business development company or small business investment company as a trustee.</li>
                          <li>The trust is revocable, and grantor(s) are accredited, individual investors.</li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What documents do I need to submit to become verified as an Accredited Investor?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <h6>The following documentation is required to prove that you can be considered an accredited investor.<br />
                          Individuals:</h6>
                        <ul>
                          <li>Net Worth verification: Submit any combination of bank statements, brokerage statements, other statements of securities holdings, and appraisal reports to assess the value of your assets. </li>
                          <li>Income-based verification: Tax returns or other government documents can support evidence. </li>
                          <li>Knowledgeable investor test, a copy of the unexpired securities license suffices.
                            Business Entities and Trusts</li>
                          <li>Show the $5 million minimum assets by furnishing a bank statement, brokerage statement and/or appraisal reports of real estate or other tangible assets held by the entity. </li>
                          <li>If a business entity does not meet the minimum assets requirement, then each business entity owner must be individually verified as an accredited investor, in which case the documentation requirements mentioned above for individuals apply. </li>
                          <li>Similarly, if a trust does not meet the minimum assets threshold, then the trust documents should be submitted to determine if the trust qualifies based on the type of trustee or if the trust is revocable.  Suppose the trust wishes to achieve accreditation under the condition that the trust is revocable. In that case, all trust grantor(s) must be accredited individually, in which case the documentation requirements mentioned above for individuals apply.</li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What is “Minimum amount investment” Exemption?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>The minimum amount investment prospectus exemption allows businesses, Partnerships and Trust to invest provided the purchase price of investment is at least $150,000 and is paid in cash at the time of the purchase.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
                <TabPanel className="cl-tabpanel">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What is the benefit for a Project Sponsor to raise equity on Capitaleta platform?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>Capitaleta management provides early bankability reviews and underwriting that enhance project debt financing, development, and operations to reduce project risk, increase efficiency, and project profitability.</p>
                        <p>Project Owners who want to recapitalize their assets can benefit from not selling all their assets but putting up to 90% of their equity interest up for sale to investors.</p>
                        <p>We can reduce the time it takes for project sponsors to raise equity capital for their projects through fractionalization.  A fundraising process that typically takes many months to complete and requires many intermediaries and tedious paperwork can be shortened into weeks at a lower cost. </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Must the project be new construction?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>Equity Investment opportunities offered on Capitaleta are diverse and include new construction, stabilized assets, and value-add projects.  Value-add investments typically involve a project sponsor buying an underperforming property and applying capital improvements and operational and marketing know-how to improve value. Returns from value-add opportunities come from income and price appreciation when the improved project is later sold.  For new construction, returns are derived somewhat from income as the project starts generating cash flow in later years but more so from price appreciation when the property is sold.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          How Can a Project Sponsor Submit a Project to Capitaleta?                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <h6>Registration</h6>
                        <h6>Register on the marketplace at <Link to='https://2bpartner.com/submission/apply/'>https://2bpartner.com/submission/apply/</Link> and submit a Letter of Intent with the following information:</h6>
                        <ul>
                          <li><strong>Type of project:</strong> Real estate, energy, hospitality, or infrastructure.</li>
                          <li><strong>Status:</strong> New, Operating, or value add.</li>
                          <li><strong>Equity 1:</strong> The total amount of Equity required for the project</li>
                          <li><strong>Equity 2:</strong> Equity Available</li>
                          <li><strong>Equity 3:</strong> Amount of equity you are looking to raise.</li>
                          <li><strong>Documents to attach:</strong> Project executive summary or business plan, including financial projections.</li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
                <TabPanel className="cl-tabpanel">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          How will I be charged for membership?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>We charge your Capitaleta wallet.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Does membership let me invest in all properties?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>Yes! Your membership provides you with access to all the offerings on Capitaleta. If your membership is active, you will be able to invest in any of our project opportunities.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Can i pay monthly?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>Yes. Please note that transaction fees will apply.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FAQ
