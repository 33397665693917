import './KYCPage.scss';
import '../../../components/UI/Button1/Button1.scss'
import { Link } from 'react-scroll';
import { useState, useEffect, useContext } from 'react';
import { kycSidenavItem } from '../../../common/KYCConstants';
import { Button1, Modal } from '../../../components';
import { Form, InputGroup } from 'react-bootstrap';
import { API_URL } from '../../../services/Api_endPoint';
import { APPLY_KYC, INVESTOR_KYC_UPDATE, INVESTOR_PROFILE_UPDATE, INVESTOR_STO, uploadMutation } from '../../../services/Query';
import Auth from '../../../services/Auth';
import { useNavigate } from 'react-router';
import axios from 'axios';
import dataContext from '../../../store/data-context';
import { AddressInfomation, CompleteSetup, InvestorQuestionaire, PersonalInformation, TypeOfInvestor } from '../kyc'

let digiShareToken = ""

const KYCPage = () => {
  const navigate = useNavigate();
  const { digiShareResponseToken } = Auth();

  useEffect(() => {
    digiShareToken = digiShareResponseToken();
  }, [digiShareResponseToken])

  const [modalShow, setModalShow] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const kycCtx = useContext(dataContext)
  const { personalDetails, setPersonalDetails,
    documents, setDocuments,
    investorQuestionaire,
    investorType,
    individualType,
    individual_nonEligible_opt1,
    individual_eligible_opt1,
    individual_eligible_opt2,
    individual_eligible_opt3,
    individual_accredited_opt1,
    individual_accredited_opt2,
    individual_accredited_opt3,
    individual_accredited_opt4,
    individual_family_opt1,
    individual_family_opt2,
    individual_family_opt3,
    individual_family_opt4,
    entityType,
    completeSetup,
    kycApply, setKycApply
  } = kycCtx

  const [document1Disabled, setDocument1Disabled] = useState(false)
  const [document2Disabled, setDocument2Disabled] = useState(false)

  // UPLOAD FILES FROM DOCUMENTETIONS 
  const [idProof1, setIdProof1] = useState({
    name: "",
    link: "",
    __typename: "FileUploaded",
    type: "",
    side: ""
  })
  const [idProof2, setIdProof2] = useState({
    name: "",
    link: "",
    __typename: "FileUploaded",
    type: "",
    side: ""
  })

  const [updateIdProof1File, setupdateIdProof1File] = useState(false);

  useEffect(() => {
    const uploadIdProof1File = async () => {
      try {
        let formData = new FormData();
        let selectedFile = document.getElementById("input-file1").files[0];
        formData.append('operations', uploadMutation)//'{"query":' + uploadMutation + '}');
        formData.append('map', '{"0": ["variables.file"]}');
        formData.append('0', selectedFile);
        const response = await axios.post(API_URL,
          formData, {
          headers: {
            'Content-Type': 'text/plain',
            'Authorization': "Bearer " + digiShareToken
          }
        });
        console.log(response.data.data.fileUpload.name);
        setIdProof1(
          preIdProof1 => (
            {
              ...preIdProof1,
              name: response.data.data.fileUpload.name,
              link: response.data.data.fileUpload.link,
              type: documents.id1type
            }
          )
        )
      } catch (error) {
        if (error.response) { // get response with a status code not in range 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) { // no response
          console.log(error.request);
        } else {
          // Something wrong in setting up the request
          console.log('Error', error.message);
        }
        console.log(error.config);
      }
    }

    if (updateIdProof1File) {
      uploadIdProof1File(digiShareToken)
    }
  }, [updateIdProof1File, documents.id1type])

  const [updateIdProof2File, setupdateIdProof2File] = useState(false);

  useEffect(() => {
    const uploadIdProof2File = async () => {
      try {
        let formData = new FormData();
        let selectedFile = document.getElementById("input-file2").files[0];
        formData.append('operations', uploadMutation)//'{"query":' + uploadMutation + '}');
        formData.append('map', '{"0": ["variables.file"]}');
        formData.append('0', selectedFile);
        const response = await axios.post(API_URL,
          formData, {
          headers: {
            'Content-Type': 'text/plain',
            'Authorization': "Bearer " + digiShareToken
          }
        });
        console.log(response.data.data.fileUpload.name);
        setIdProof2(
          preIdProof2 => (
            {
              ...preIdProof2,
              name: response.data.data.fileUpload.name,
              link: response.data.data.fileUpload.link,
              type: documents.id2type
            }
          )
        )
      } catch (error) {
        if (error.response) { // get response with a status code not in range 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) { // no response
          console.log(error.request);
        } else {
          // Something wrong in setting up the request
          console.log('Error', error.message);
        }
        console.log(error.config);
      }
    }

    if (updateIdProof2File) {
      uploadIdProof2File(digiShareToken)
    }
  }, [updateIdProof2File, documents.id2type])

  // SUBMIT INVESTOR PERSONAL AND ADDRESS DETAILS 

  const submitProfile = async () => {
    const response = await axios.post(API_URL,
      {
        query: INVESTOR_PROFILE_UPDATE,
        variables: {
          "data": {
            "firstName": personalDetails.first_name,
            "lastName": personalDetails.last_name,
            "address": personalDetails.street_address,
            "zip": personalDetails.postal_code,
            "town": personalDetails.city,
            "state": personalDetails.province,
            "country": personalDetails.country_of_citizenship,
            "phone": personalDetails.phone_number,
            "passportNumber": personalDetails.id1,
            "nationalID": personalDetails.id1,
            "driversLicenseID": personalDetails.id2,
            "birthDate": personalDetails.birth_date,
            "notes": "test"
          }
        }
      }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + digiShareToken
      }
    });
    if (response.data.hasOwnProperty("errors")) {
      alert("Error");
    }
    else if (response.data.hasOwnProperty("data")) {
      console.log(response)
      submitQuestionaire();
    }
  }


  // SUBMIT INVESTOR KYC DETAILS
  const submitQuestionaire = async () => {
    const response = await axios.post(API_URL,
      {
        query: INVESTOR_KYC_UPDATE,
        variables: {
          "data": {
            "expected-withdraw": "",
            "knowledge-investment": "",
            "primary-goal": "",
            "annual-income": "",
            "current-future-income": "",
            "financial-situation": "",
            "net-worth": "",
            "investment-account-percentage": "",
            "age-group": "",
            "financial-decision": "",
            "tolerate-decline": "",
            "possible-losses-gains": "",
            "decision-14": "",
            "age": investorQuestionaire.age,
            "investment_knowledge": investorQuestionaire.investment_knowledge,
            "investment_experience": investorQuestionaire.investment_experience,
            "investment_motivation": investorQuestionaire.investment_motivation,
            "investment_planning": investorQuestionaire.investment_planning,
            "length_of_investment": investorQuestionaire.length_of_investment,
            "income_level": investorQuestionaire.income_level,
            "household_income": investorQuestionaire.household_income,
            "politically_exposed_foreignperson": investorQuestionaire.politically_exposed_foreignperson,
            "politically_exposed_foreignperson_descr": investorQuestionaire.politically_exposed_foreignperson_descr,
            "voting_right": investorQuestionaire.voting_right,
            "voting_right_descr": investorQuestionaire.voting_right_descr,
            "investor_documents": {
              idProof1,
              idProof2
            },
            "investor": {
              "type_of_investor": investorType,
              'individual': {
                'individualType': individualType,
                'individual_nonEligible_opt1': individual_nonEligible_opt1,
                'individual_eligible_opt1': individual_eligible_opt1,
                'individual_eligible_opt2': individual_eligible_opt2,
                'individual_eligible_opt3': individual_eligible_opt3,
                'individual_accredited_opt1': individual_accredited_opt1,
                'individual_accredited_opt2': individual_accredited_opt2,
                'individual_accredited_opt3': individual_accredited_opt3,
                'individual_accredited_opt4': individual_accredited_opt4,
                'individual_family_opt1': individual_family_opt1,
                'individual_family_opt2': individual_family_opt2,
                'individual_family_opt3': individual_family_opt3,
                'individual_family_opt4': individual_family_opt4,
              },
              'entity': {
                'entityType': entityType
              }
            },
            'CompleteSetup': {
              'country_of_residence': completeSetup.country_of_residence,
              'social_insurance_number': completeSetup.social_insurance_number,
              'beneficial_purchaser_name': completeSetup.beneficial_purchaser_name,
              'beneficial_purchaser_address': completeSetup.beneficial_purchaser_address,
              'registration_name': completeSetup.registration_name,
              'registration_account_reference': completeSetup.registration_account_reference,
              'registration_address': completeSetup.registration_address,
              'delivery_instruction': completeSetup.delivery_instruction,
              'delivery_account_reference': completeSetup.delivery_account_reference,
              'delivery_name': completeSetup.delivery_name,
            },
          }
        }
      }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + digiShareToken
      }
    });
    if (response.data.hasOwnProperty("errors")) {
      alert("Error");
    }
    else if (response.data.data.hasOwnProperty("fillKyc")) {
      console.log(response)
    }
  }



  // APPLY KYC 
  const applyKyc = async () => {
    const response = await axios.post(API_URL,
      {
        query: APPLY_KYC,
        variables: {
          "applied": true
        }
      }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + digiShareToken
      }
    });
    if (response.data.hasOwnProperty("errors")) {
      alert("Error");
      setModalShow(true);
      setModalMsg('Error')
    }
    else if (response.hasOwnProperty("data")) {
      console.log(response.data);
      setModalShow(true);
      setModalMsg('You successfully submitted your KYC data.')
    }
  }

  // GET KYC APPLY STATUS
  const getKycApplied = () => {
    fetch(API_URL, {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + digiShareToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ query: INVESTOR_STO })
    }).then(response => response.json())
      .then(data => {
        let kycStatus = data.data.investorSto;
        console.log(kycStatus.applied)
        setKycApply(kycStatus.applied)
      });
  }


  //Final Button Click
  const submitDocuments = async () => {
    await submitProfile();
    await applyKyc();
    getKycApplied()
  }

  const saveDocuments = () => {
    submitProfile();
    setModalShow(true);
    setModalMsg('Your KYC info was saved successfully.')
  }
  // const saveContDocuments = () => {
  // submitProfile();
  // submitQuestionaire();
  // navigate('/marketplace');
  // }


  return (

    <div className="container-fluid px-5">
      <div className="row">
        <div className="col-3 kycLeftPart position-relative sectionPaddingY">
          <div className='position-fixed'>
            <div className='d-flex flex-column'>
              <h1 className='text-capitalize fw-bold userName'>{personalDetails.first_name} {personalDetails.last_name}</h1>
              <p>Investor since October 2022</p>
            </div>
            <div className='leftPartMenuPart'>
              <nav className='d-block'>
                <ul className='p-0 leftPartMenu'>
                  {
                    kycSidenavItem.map((item) => (
                      <li key={item.id}>
                        <Link to={item.id} spy={true} smooth={true} offset={-115} duration={100} className='d-flex justify-content-start align-items-center kycSidenavItem'>{item.name}</Link>
                      </li>
                    ))
                  }
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="col-9 mt-3">
          <section className='sectionPaddingY rounded-start d-flex' style={{ borderLeft: '1px solid var(--secondary)' }}>
            <div className='px-3'>
              <svg height="100%" width="1.5rem" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><g fill="var(--secondary)" stroke="var(--secondary)" stroke-width="1"><circle cx="6" cy="6" fill="none" r="5.5" stroke="var(--secondary)" stroke-linecap="round" stroke-linejoin="round"></circle><line fill="none" stroke-linecap="round" stroke-linejoin="round" x1="4" x2="8" y1="4" y2="8"></line><line fill="none" stroke-linecap="round" stroke-linejoin="round" x1="8" x2="4" y1="4" y2="8"></line></g></svg>
            </div>
            <div className='container-fluid'>
              <div className="row">
                <div className="col">
                  <h6 className='text-uppercase'>
                    Missing KYC information
                  </h6>
                  <p>Information is missing from your KYC. Please complete the following:</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ul className='list-group' style={{ fontSize: '13px' }}>
                    {!personalDetails.first_name &&
                      <li className='list-group-item'>
                        <span>Fisrt Name</span>
                      </li>
                    }
                    {!personalDetails.last_name &&
                      <li className='list-group-item'>
                        <span>Last Name</span>
                      </li>
                    }
                    {!personalDetails.phone_number &&
                      <li className='list-group-item'>
                        <span>Phone Number</span>
                      </li>
                    }
                    {!personalDetails.birth_date &&
                      <li className='list-group-item'>
                        <span>Birthdate</span>
                      </li>
                    }
                    {!personalDetails.email &&
                      <li className='list-group-item'>
                        <span>Email Id</span>
                      </li>
                    }
                    {!personalDetails.street_address &&
                      <li className='list-group-item'>
                        <span>Street Address</span>
                      </li>
                    }
                    {!personalDetails.city &&
                      <li className='list-group-item'>
                        <span>City</span>
                      </li>
                    }
                    {!personalDetails.postal_code &&
                      <li className='list-group-item'>
                        <span>Postal Code</span>
                      </li>
                    }
                    {!personalDetails.province &&
                      <li className='list-group-item'>
                        <span>Province</span>
                      </li>
                    }
                    {!personalDetails.country_of_citizenship &&
                      <li className='list-group-item'>
                        <span>Country</span>
                      </li>
                    }
                  </ul>
                </div>
                <div className="col">
                  <ul className='list-group' style={{ fontSize: '13px' }}>
                    {!investorQuestionaire.age &&
                      <li className='list-group-item'>
                        <span>Age</span>
                      </li>
                    }
                    {!investorQuestionaire.investment_knowledge &&
                      <li className='list-group-item'>
                        <span>Investment Knowledge</span>
                      </li>
                    }
                    {!investorQuestionaire.investment_experience &&
                      <li className='list-group-item'>
                        <span>Investment Experience</span>
                      </li>
                    }
                    {!investorQuestionaire.investment_motivation &&
                      <li className='list-group-item'>
                        <span>Investment Motivation</span>
                      </li>
                    }
                    {!investorQuestionaire.investment_planning &&
                      <li className='list-group-item'>
                        <span>Investment Planning</span>
                      </li>
                    }
                    {!investorQuestionaire.length_of_investment &&
                      <li className='list-group-item'>
                        <span>Length of investment</span>
                      </li>
                    }
                    {!investorQuestionaire.income_level &&
                      <li className='list-group-item'>
                        <span>Income Level</span>
                      </li>
                    }
                    {!investorQuestionaire.household_income &&
                      <li className='list-group-item'>
                        <span>Household Income</span>
                      </li>
                    }
                    {!investorQuestionaire.politically_exposed_foreignperson &&
                      <li className='list-group-item'>
                        <span>Politically Exposed Foreignperson</span>
                      </li>
                    }
                    {!investorQuestionaire.voting_right &&
                      <li className='list-group-item'>
                        <span>Voting Right</span>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <PersonalInformation />

          <AddressInfomation />

          <InvestorQuestionaire />

          <TypeOfInvestor />

          <CompleteSetup />

          <section className='sectionPaddingY authPageHeight d-flex flex-column justify-content-between' id='document'>
            <div>
              <h5 className='text-uppercase'>
                Document
              </h5>
              {investorType === 'Individual' &&
                <>
                  {
                    individualType === 'Non-Eligible Investor' &&
                    <div className="container-fluid">
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id1type} onChange={e => setDocuments({ ...documents, id1type: e.target.value })} onClick={() => setDocument1Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document1Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Utility Bill or bank Statement'>Utility Bill or bank Statement</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id1} onChange={e => setPersonalDetails({ ...personalDetails, id1: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center'>
                          <input id="input-file1" type="file" className="d-none" onChange={() => setupdateIdProof1File(true)} disabled={kycApply} />
                          <label id="input-file-label1" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file1">Click here to Uplaod</label>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id2type} onChange={e => setDocuments({ ...documents, id2type: e.target.value })} onClick={() => setDocument2Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document2Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Utility Bill or bank Statement'>Utility Bill or bank Statement</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id2} onChange={e => setPersonalDetails({ ...personalDetails, id2: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center' >
                          <input id="input-file2" type="file" className="d-none" onChange={() => setupdateIdProof2File(true)} disabled={kycApply} />
                          <label id="input-file-label2" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file2">Click here to Uplaod</label>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    individualType === 'Eligible Investor' &&
                    <div className="container-fluid">
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id1type} onChange={e => setDocuments({ ...documents, id1type: e.target.value })} onClick={() => setDocument1Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document1Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Net worth or Income verification'>Net worth or Income verification</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id1} onChange={e => setPersonalDetails({ ...personalDetails, id1: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center'>
                          <input id="input-file1" type="file" className="d-none" onChange={() => setupdateIdProof1File(true)} disabled={kycApply} />
                          <label id="input-file-label1" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file1">Click here to Uplaod</label>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id2type} onChange={e => setDocuments({ ...documents, id2type: e.target.value })} onClick={() => setDocument2Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document2Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Net worth or Income verification'>Net worth or Income verification</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id2} onChange={e => setPersonalDetails({ ...personalDetails, id2: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center' >
                          <input id="input-file2" type="file" className="d-none" onChange={() => setupdateIdProof2File(true)} disabled={kycApply} />
                          <label id="input-file-label2" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file2">Click here to Uplaod</label>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    individualType === 'Accredited Investor' &&
                    <div className="container-fluid">
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id1type} onChange={e => setDocuments({ ...documents, id1type: e.target.value })} onClick={() => setDocument1Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document1Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Net worth or Income verification'>Net worth or Income verification</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id1} onChange={e => setPersonalDetails({ ...personalDetails, id1: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center'>
                          <input id="input-file1" type="file" className="d-none" onChange={() => setupdateIdProof1File(true)} disabled={kycApply} />
                          <label id="input-file-label1" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file1">Click here to Uplaod</label>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id2type} onChange={e => setDocuments({ ...documents, id2type: e.target.value })} onClick={() => setDocument2Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document2Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Net worth or Income verification'>Net worth or Income verification</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id2} onChange={e => setPersonalDetails({ ...personalDetails, id2: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center' >
                          <input id="input-file2" type="file" className="d-none" onChange={() => setupdateIdProof2File(true)} disabled={kycApply} />
                          <label id="input-file-label2" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file2">Click here to Uplaod</label>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    individualType === 'Family, Friends and Business Associates' &&
                    <div className="container-fluid">
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id1type} onChange={e => setDocuments({ ...documents, id1type: e.target.value })} onClick={() => setDocument1Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document1Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Utility Bill or bank Statement'>Utility Bill or bank Statement</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id1} onChange={e => setPersonalDetails({ ...personalDetails, id1: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center'>
                          <input id="input-file1" type="file" className="d-none" onChange={() => setupdateIdProof1File(true)} disabled={kycApply} />
                          <label id="input-file-label1" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file1">Click here to Uplaod</label>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id2type} onChange={e => setDocuments({ ...documents, id2type: e.target.value })} onClick={() => setDocument2Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document2Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Utility Bill or bank Statement'>Utility Bill or bank Statement</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id2} onChange={e => setPersonalDetails({ ...personalDetails, id2: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center' >
                          <input id="input-file2" type="file" className="d-none" onChange={() => setupdateIdProof2File(true)} disabled={kycApply} />
                          <label id="input-file-label2" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file2">Click here to Uplaod</label>
                        </div>
                      </div>
                    </div>
                  }
                </>
              }
              {investorType === 'Entity' &&
                <>
                  {(entityType === "Private Corporation" || entityType === "Partnerships and Others" || entityType === "Trust") &&
                    <div className="container-fluid">
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id1type} onChange={e => setDocuments({ ...documents, id1type: e.target.value })} onClick={() => setDocument1Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document1Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Resolution appointing as Representative '>Resolution appointing as Representative </option>
                              <option value='Networth verification Or Income Verification'>Networth verification Or Income Verification</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id1} onChange={e => setPersonalDetails({ ...personalDetails, id1: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center'>
                          <input id="input-file1" type="file" className="d-none" onChange={() => setupdateIdProof1File(true)} disabled={kycApply} />
                          <label id="input-file-label1" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file1">Click here to Uplaod</label>
                        </div>                      </div>
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id2type} onChange={e => setDocuments({ ...documents, id2type: e.target.value })} onClick={() => setDocument2Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document2Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Resolution appointing as Representative '>Resolution appointing as Representative </option>
                              <option value='Networth verification Or Income Verification'>Networth verification Or Income Verification</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id2} onChange={e => setPersonalDetails({ ...personalDetails, id2: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center' >
                          <input id="input-file2" type="file" className="d-none" onChange={() => setupdateIdProof2File(true)} disabled={kycApply} />
                          <label id="input-file-label2" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file2">Click here to Uplaod</label>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id2type} onChange={e => setDocuments({ ...documents, id2type: e.target.value })} onClick={() => setDocument2Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document2Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Resolution appointing as Representative '>Resolution appointing as Representative </option>
                              <option value='Networth verification Or Income Verification'>Networth verification Or Income Verification</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id2} onChange={e => setPersonalDetails({ ...personalDetails, id2: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center' >
                          <input id="input-file2" type="file" className="d-none" onChange={() => setupdateIdProof2File(true)} disabled={kycApply} />
                          <label id="input-file-label2" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file2">Click here to Uplaod</label>
                        </div>
                      </div>
                    </div>
                  }
                  {(entityType === "Public Corporation" || entityType === "Financial Institution" || entityType === "Pension and Investments Funds" || entityType === "Government" || entityType === 'Registered Charity') &&
                    <div className="container-fluid">
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id1type} onChange={e => setDocuments({ ...documents, id1type: e.target.value })} onClick={() => setDocument1Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document1Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Upload Resolution appointing as Representative'>Upload Resolution appointing as Representative</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id1} onChange={e => setPersonalDetails({ ...personalDetails, id1: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center'>
                          <input id="input-file1" type="file" className="d-none" onChange={() => setupdateIdProof1File(true)} disabled={kycApply} />
                          <label id="input-file-label1" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file1">Click here to Uplaod</label>
                        </div>                      </div>
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-9">
                          <InputGroup>
                            <Form.Select aria-label="Floating label select example" value={documents.id2type} onChange={e => setDocuments({ ...documents, id2type: e.target.value })} onClick={() => setDocument2Disabled(true)} disabled={kycApply}>
                              <option value="" disabled={document2Disabled ? true : null}>Select a Option</option>
                              <option value='Government-issued issued ID'>Government-issued issued ID</option>
                              <option value='Upload Resolution appointing as Representative'>Upload Resolution appointing as Representative</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter ID Number' value={personalDetails.id2} onChange={e => setPersonalDetails({ ...personalDetails, id2: e.target.value })} disabled={kycApply} />
                          </InputGroup>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center' >
                          <input id="input-file2" type="file" className="d-none" onChange={() => setupdateIdProof2File(true)} disabled={kycApply} />
                          <label id="input-file-label2" className="btn1" style={{ backgroundColor: 'var(--secondary)' }} for="input-file2">Click here to Uplaod</label>
                        </div>
                      </div>
                    </div>
                  }
                </>
              }
              {investorType === 'Investment Representative / Advisor' &&
                <></>
              }
            </div>
            <div className='d-flex justify-content-center gap-3'>
              <Button1 style={{ backgroundColor: 'var(--primary)' }} onClick={saveDocuments}>Save</Button1>
              {/* <Button1 style={{ backgroundColor: 'var(--primary)' }} onClick={saveContDocuments}>Save & Continue</Button1> */}
              <Button1 style={{ backgroundColor: 'var(--secondary)' }} onClick={submitDocuments}>Submit</Button1>
            </div>
          </section>
        </div>
      </div >
      {
        modalShow &&
        <Modal onClose={setModalShow}>
          <h3 className='text-center' style={{ color: 'var(--primary)' }}>{modalMsg}</h3>
          <div className='d-flex justify-content-center'>
            <Button1 style={{ backgroundColor: 'var(--secondary)' }} onClick={() => { setModalShow(false); navigate('/marketplace') }}>Close</Button1>
          </div>
        </Modal>
      }
    </div >
  )
}

export default KYCPage
