import submitIcon from '../assets/images/submit-project.png';
import investIcon from '../assets/images/dollar-sign.png';
import propertyImg1 from '../assets/images/property-img_03.png';
import propertyImg2 from '../assets/images/property-img_05.png';
import propertyImg3 from '../assets/images/property-img_07.png';

import icon1 from '../assets/images/icon1.gif';
import icon2 from '../assets/images/icon2.gif';
import icon3 from '../assets/images/icon3.gif';
import icon4 from '../assets/images/icon4.png';

import newsImg1 from '../assets/images/news_01.png';
import newsImg2 from '../assets/images/news_02.png';
import newsImg3 from '../assets/images/news_03.png';



export const guestMenus = [
    {
        name: "FAQ",
        link: "/faq",
        icon: false,
        sunMenu: false,
    },
    {
        name: "Marketplace",
        link: "/marketplace",
        icon: false,
        sunMenu: false,
    },
    {
        name: "Investors",
        link: "/investors",
        icon: <i class="bi bi-chevron-down"></i>,
        subMenu: [
            {
                name: "Overview",
                link: "/investors",
            },
            {
                name: "How It Works",
                link: "/how-it-works",
            },
            {
                name: "Investment Approach",
                link: "/investment-approach",
            },
            {
                name: "Why Invest",
                link: "/why-invest",
            },
            {
                name: "Steps to Invest",
                link: "/steps-to-invest",
            },
        ]
    },
    {
        name: "Investor Resources",
        link: "/resources",
        icon: <i class="bi bi-chevron-down"></i>,
        subMenu: [
            {
                name: "Project Review Process",
                link: "/project-review",
            },
            {
                name: "Investor Relations",
                link: "/investor-relation",
            },
            {
                name: "Investors Commitment",
                link: "/investors-commitment",
            },
            {
                name: "Events & Webinars",
                link: "/events-webiners",
            },
        ]
    },
    {
        name: "Sponsors",
        link: "/sponsors",
        icon: <i class="bi bi-chevron-down"></i>,
        subMenu: [
            {
                name: "Overview",
                link: "/sponsors",
            },
            {
                name: "Capital Raising Process",
                link: "/capital-raising",
            },
            {
                name: "Project Evaluation Process",
                link: "/project-evaluation",
            },
            {
                name: "Post Approval Process",
                link: "/post-approval",
            },
            {
                name: "Request Information",
                link: "/request-information",
            },
        ]
    },
    {
        name: "Login",
        link: "/login",
        icon: false,
        sunMenu: false
    }
]

export const exploreOpportunitiesLeftTopics = [
    {
        imgAlt: "Become an Investor",
        imgSrc: submitIcon,
        title: "Become an Investor",
        link: "/registration",
        para1: "Access unique offerings across a wide range of project types.",
        para2: "Our rigorous technology-driven, experience-led project selection process provides greater transparency and attractive returns for CapitaLeta investors."
    },
    {
        imgAlt: "Submit your Project",
        imgSrc: investIcon,
        title: "Submit your Project",
        link: "/",
        para1: "CapitaLeta offers best-in-class solutions to project sponsors through affordable capital, flexibility, and speed of execution.",
        para2: "We can help you achieve your goals, whether to raise equity and debt for new projects or recycle capital for operating projects."
    }

]

export const homeAccordionItems = [
    {
        uuid: 1,
        heading: "Return on Investments",
        content: "By investing in projects on the CapitaLeta marketplace, investors can earn returns through cash flow from project operations, project refinancing, and asset sales.",
    },
    {
        uuid: 2,
        heading: "Choice and Control",
        content: "Most investments depend on the company management&#39;s decision on which project you should invest in or dispose of. This is not the case with CapitaLeta. We give you the power to choose.",
    },
    {
        uuid: 3,
        heading: "Accountability and Transparency–",
        content: "Investors now have access to the CapitaLeta transaction ledger, adding a new layer of accountability that holds CapitaLeta accountable to operate with integrity in the interests of its investors and project sponsors.",
    },
    {
        uuid: 4,
        heading: "Simple, Secure and Accessible",
        content: "CapitaLeta conducts due diligence and risk underwriting on all projects to ensure safety, simplicity, and investment transparency. Automating our investment operations removes various impediments while speeding up the transaction process. For as little as $1,000, an investor may be fully invested in as little as 7 minutes.",
    },
];

export const featuredProjectItems = [
    {
        heading: "Multiplex Hotel",
        location: "Atlanta, Georgia",
        imgSrc: propertyImg1,
        imgAlt: "Multiplex Hotel",
        status: "Close",
        investedAmd: "$9.5 M.",
        shortDesc: "Newly built in 2018 as luxury apartments, Atlanta, Georgia is poised for conversion to a high-end student housing community.",
        investmentTerm: "12 Months",
        minInvestmentAmount: "$1,000",
        projectedAnnualReturn: "8.25%",
        targetAverageCashYield: "4.50%",
        targetEquityMultiple: "1.75x",
        investorEquityRaise: "3.35x",
        link: "./project-details"
    },
    {
        heading: "Multiplex Hotel",
        location: "Atlanta, Georgia",
        imgSrc: propertyImg2,
        imgAlt: "Multiplex Hotel",
        status: "Close",
        investedAmd: "$9.5 M.",
        shortDesc: "Newly built in 2018 as luxury apartments, Atlanta, Georgia is poised for conversion to a high-end student housing community.",
        investmentTerm: "12 Months",
        minInvestmentAmount: "$1,000",
        projectedAnnualReturn: "8.25%",
        targetAverageCashYield: "4.50%",
        targetEquityMultiple: "1.75x",
        investorEquityRaise: "3.35x",
        link: "./project-details"
    },
    {
        heading: "Multiplex Hotel",
        location: "Atlanta, Georgia",
        imgSrc: propertyImg3,
        imgAlt: "Multiplex Hotel",
        status: "Live",
        investedAmd: "$9.5 M.",
        shortDesc: "Newly built in 2018 as luxury apartments, Atlanta, Georgia is poised for conversion to a high-end student housing community.",
        investmentTerm: "12 Months",
        minInvestmentAmount: "$1,000",
        projectedAnnualReturn: "8.25%",
        targetAverageCashYield: "4.50%",
        targetEquityMultiple: "1.75x",
        investorEquityRaise: "3.35x",
        link: "./project-details"
    }
]

export const submitYourProjectLeft = [
    {
        imgSrc: icon1,
        imgAlt: "icon1",
        title: "Raise Capital",
        para: "CapitaLets work with project sponsors to raise Equity for new projects and enhance capital recycling..."
    },
    {
        imgSrc: icon2,
        imgAlt: "icon2",
        title: "Project Bankability Support",
        para: "We offer project bankability support, which comprises a project and sponsors' due diligence study and project risk underwriting, for the advantage of project sponsors and other stakeholders..."
    }
]

export const submitYourProjectRight = [
    {
        imgSrc: icon3,
        imgAlt: "icon3",
        title: "Investor Diversification",
        para: "CapitaLeta can successfully assist project sponsors in expanding their investor base rather than being limited to a few equity partners..."
    },
    {
        imgSrc: icon4,
        imgAlt: "icon4",
        title: "Management Support",
        para: "The Portfolio Operation Team (POT) provides continuing assistance to the project sponsor throughout its life cycle..."
    }
]

export const raiseCapitalListText = [
    {
        text: "We will underwrite and raise capital for your project on CapitaLeta Marketplace."
    },
    {
        text: "We will assist you in leveraging our platform capability for management, monitoring and reporting."
    }
]

export const latestNewsAndInsightsIyems = [
    {
        imgSrc: newsImg1,
        imgAlt: 'newsImg1',
        detailsLink: '/',
        user: 'Admin',
        date: '16 Aug, 2021',
        haedLine: 'Discuss with our experienced Team',
        smalldesc: 'Diversify your investments with selected projects with the potential to generate income and grow inter value.'
    },
    {
        imgSrc: newsImg2,
        imgAlt: 'newsIm2',
        detailsLink: '/',
        user: 'Admin',
        date: '16 Aug, 2021',
        haedLine: 'Discuss with our experienced Team',
        smalldesc: 'Diversify your investments with selected projects with the potential to generate income and grow inter value.'
    },
    {
        imgSrc: newsImg3,
        imgAlt: 'newsImg3',
        detailsLink: '/',
        user: 'Admin',
        date: '16 Aug, 2021',
        haedLine: 'Discuss with our experienced Team',
        smalldesc: 'Diversify your investments with selected projects with the potential to generate income and grow inter value.'
    },
]