import { useState } from "react";
import './Home.scss'
import { Link } from 'react-router-dom';
import { exploreOpportunitiesLeftTopics, homeAccordionItems, raiseCapitalListText, submitYourProjectLeft, submitYourProjectRight } from "../../../common/Constants";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Modal from 'react-bootstrap/Modal'

import whyInvImg from '../../../assets/images/collage-img.png';
import playIcon from '../../../assets/images/video-icon.png';
import smarteWayImg from '../../../assets/images/handshake-img.png';
import { Button1, HomeBanner, FeaturedProject,LatestNewsAndInsights } from "../../../components";


const Home = () => {
  const [show, setShow] = useState(false);
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <>
      <HomeBanner />
      <section className='explore-opportunities-section sectionPaddingY'>
        <div className="container">
          <div className="row container-inn">
            <h3 className='container-inn-heading'>
              CapitaLeta raises capital for project sponsors by allowing investors to invest in institutional-quality project opportunities that are secure, transparent, and profitable while adhering to ESG principles.
            </h3>
            <div className="row">
              <div className="col">
                <h4>
                  A comprehensive project financing platform <br />
                  designed for safety, transparency, and growth.
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex align-items-center">
                <div className="expopp-block">
                  <div className="expopp-block-inn">
                    <h3 className='topicHeading'>
                      Explore Opportunities
                    </h3>
                    <p>Create your account to review, compare, and select the investment opportunity that best meets your investment criteria.</p>
                    <Link to={'/marketplace'}>
                      <Button1 style={{ backgroundColor: 'var(--secondary)' }}>
                        Search Marketplace <i class="bi bi-arrow-right"></i>
                      </Button1>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                {
                  exploreOpportunitiesLeftTopics.map((item, index) => (
                    <div className="icon-block" key={index}>
                      <div className="icon-block-icon">
                        <div className='icon-block-img'>
                          <img src={item.imgSrc} alt={item.imgAlt} />
                        </div>
                      </div>
                      <div className="icon-block-container">
                        <Link to={item.link} ><h5>{item.title}</h5></Link>
                        <p>{item.para1}</p>
                        <p>{item.para2}</p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section >

      <section className="why-capitaleta sectionPaddingY">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 whyInv-right-block">
              <img src={whyInvImg} alt="Why Invest with CapitaLeta?" />
            </div>
            <div className="col-12 col-md-6">
              <div className="whyInv-left-block">
                <h2 className='topicHeading'>Why Invest <br /> with CapitaLeta?</h2>
                <h6>A smarter investment marketplace with as low as a $1,000 minimum investment.</h6>
                <div className="whyInv-accordian">
                  <Accordion allowZeroExpanded preExpanded={[1]}>
                    {homeAccordionItems.map((item, index) => (
                      <AccordionItem uuid={item.uuid} key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {item.heading}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>{item.content}</p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
                <div className="whyInvp-btn d-flex gap-3">
                  <Link to={'/why-invest'}>
                    <Button1 style={{ backgroundColor: 'var(--secondary)' }}>Read More</Button1>
                  </Link>
                  <Link to={'/how-it-works'}>
                    <Button1 style={{ backgroundColor: 'var(--primary)' }}>How It Works <i class="bi bi-arrow-right"></i></Button1>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='featured-project sectionPaddingY'>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center featured-project-heading">
              <h2 className='topicHeading'>Featured Project</h2>
              <h4 className='smHeading'>Capitaleta makes Investing in projects Simple, Accessible, and affordable.</h4>
              <p>We help project sponsors raise capital more efficiently, and give investors access to secure and profitable project investments.</p>
            </div>
          </div>
          <div className='featured-project-properties-list'>
            <div className='row'>
              {
                featuredProjectItems.map((item, index) => (
                  <div className='col-12 col-lg-4'>
                    <div className='featured-project-property'>
                      <div className='featured-project-property-description'>
                        <h5>{item.heading}</h5>
                        <p><i class="bi bi-geo-alt"></i>{item.location}</p>
                        <div className={`p-status ${item.status === 'Live' ? 'live-status' : 'closed-status'}`} >
                          <span className='status live'><span className='bullet-icon'><i class="bi bi-circle-fill"></i></span> <span className='status-text'>{item.status}</span></span>
                        </div>
                      </div>
                      <div className='featured-project-property-img'>
                        <img src={item.imgSrc} alt={item.imgAlt} />
                        <div className='invest-amt'>
                          <h6>Invested: {item.investedAmd}</h6>
                        </div>
                      </div>
                      <div className='featured-project-property-specification'>
                        <div className='featured-project-p-short-desc'><p>{item.shortDesc}</p></div>
                        <div className='featured-project-p-spec'>
                          <ul className='list-bg-color'>
                            <li><span className='spec-name'>Investment Term</span><span className='spec-qty'>{item.investmentTerm}</span></li>
                            <li><span className='spec-name'>Min Investment Amount</span><span className='spec-qty'>{item.minInvestmentAmount}</span></li>
                            <li><span className='spec-name'>Projected Annual Return</span><span className='spec-qty'>{item.projectedAnnualReturn}</span></li>
                            <li><span className='spec-name'>Target Average Cash Yield</span><span className='spec-qty'>{item.targetAverageCashYield}</span></li>
                            <li><span className='spec-name'>Target Equity Multiple</span><span className='spec-qty'>{item.targetEquityMultiple}</span></li>
                            <li><span className='spec-name'>Investor Equity Raise</span><span className='spec-qty'>{item.investorEquityRaise}</span></li>
                          </ul>
                        </div>
                        <div className="featured-project-button">
                          <Link to='/projectdetails'>
                            <Button1 style={{ backgroundColor: 'var(--primary)' }}>
                              View Project Details <i class="bi bi-arrow-right"></i>
                            </Button1>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section> */}
      <FeaturedProject />

      <section className='submit-your-project sectionPaddingY'>
        <div className="container">
          <div className="row text-center mb-3">
            <h2 className='topicHeading'>Submit Your Project</h2>
            <h4 className='smHeading'>Raise Equity, Debt, and enhance capital recycling for efficient project financing and recapitalization.</h4>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              {
                submitYourProjectLeft.map((item, index) => (
                  <div className="icon-block" key={index}>
                    <div className="icon-block-icon">
                      <div className='icon-block-img'>
                        <img src={item.imgSrc} alt={item.imgAlt} />
                      </div>
                    </div>
                    <div className="icon-block-container">
                      <h5>{item.title}</h5>
                      <p>{item.para}</p>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="col-12 col-md-4 mb-3 mb-md-0">
              <div className="video-block">
                <Button1 onClick={() => setShow(true)} style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <img src={playIcon} alt="playIcon" />
                </Button1>
              </div>
              <Modal size="lg" show={show} onHide={() => setShow(false)}>
                {/* <Modal.Header closeButton>
                  <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                <Modal.Body style={{ position: 'relative', height: '70vh' }}>
                  <iframe
                    src="https://player.vimeo.com/video/731228263?h=02ef281f8d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                    style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', margin: 'auto', width: '100%', height: '100%' }}
                    title="EnjoyingSea.mp4" />
                </Modal.Body>
                <Modal.Footer>
                  <Button1 onClick={() => setShow(false)} style={{ backgroundColor: 'var(--secondary)' }}>
                    Close
                  </Button1>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="col-12 col-md-4">
              {
                submitYourProjectRight.map((item, index) => (
                  <div className="icon-block" key={index}>
                    <div className="icon-block-icon">
                      <div className='icon-block-img'>
                        <img src={item.imgSrc} alt={item.imgAlt} />
                      </div>
                    </div>
                    <div className="icon-block-container">
                      <h5>{item.title}</h5>
                      <p>{item.para}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section>

      <section className="raise-capital sectionPaddingY">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <img src={smarteWayImg} alt="smarteWayImg" />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center">
              <div>
                <h2 className="topicHeading">A Smarter Way To Raise Capital.</h2>
                <h6 className="smHeading">Submit your project and discover a new partner for your project.</h6>
                <ul>
                  {
                    raiseCapitalListText.map((item, index) => (
                      <li key={index}>{item.text}</li>
                    ))
                  }
                </ul>
                <div className="d-flex">
                  <Button1 onClick={topFunction} style={{ backgroundColor: 'var(--secondary)' }}>
                    Submit Your Project <i className="bi bi-arrow-right"></i>
                  </Button1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="latest-news-and-insights sectionPaddingY">
        <div className="container">
          <div className="row text-center">
            <h6 style={{ color: 'var(--secondary)' }}>News Insights</h6>
            <h2 className="topicHeading">Latest News and Insights</h2>
          </div>
          <div className='row news-blocks'>
            {
              latestNewsAndInsightsIyems.map((item, index) => (
                <div className='col-12 col-md-4 mb-2 mb-md-0' key={index}>
                  <div className='news-blocks-inn'>
                    <div className='imgblock'>
                      <img src={item.imgSrc} alt={item.imgAlt} />
                      <Link to={item.detailsLink}>
                        <span className="read-more">
                          <Button1 style={{ backgroundColor: 'var(--secondary)' }}>Read More</Button1>
                        </span>
                      </Link>
                    </div>
                    <div className='news-content'>
                      <h6><span className='cl-user'><i className="bi bi-person"></i> {item.user}</span><span className='cl-date'><i className="bi bi-calendar4-event"></i> {item.date}</span></h6>
                      <Link to={item.detailsLink}>
                        <h5>{item.haedLine}</h5>
                      </Link>
                      <p>{item.smalldesc}</p>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section> */}
      <LatestNewsAndInsights/>

    </>
  )
}

export default Home
