import './StaticThreeBlock.scss';

const StaticThreeBlock = (props) => {
    return (
        <div className="three-blocks">
            <div className="container">
                <div className='row'>
                    {
                        props.items.map((item, index) => (
                            <div className='col-12 col-lg-4 mb-2 mb-lg-0 investor-blocks' key={index}>
                                <div className="card-title" style={{ backgroundColor: `${item.color}` }}>
                                    <h5>{item.haeding}</h5>
                                </div>
                                <div className="card-img" style={{ backgroundImage: `url(${item.imgSrc})` }}>
                                    {item.imageSrc &&
                                        <img src={item.imageSrc} alt={item.imageAlt} />
                                    }
                                </div>
                                <div className="cards-contents" style={{ borderBottom: `2px solid ${item.color}` }}>
                                    <p>{item.para}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default StaticThreeBlock
