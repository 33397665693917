import { Link } from "react-router-dom"
import Logo from "../../../assets/images/capitaletaLogo.png";
import './Card.scss';
import { Alert } from "react-bootstrap";
const Card = (props) => {
    return (
        <div className="LoginRegister-container pageHeight">
            <div className="main-box">
                <div className="main-box-logo">
                    <Link to={'/'}><img src={Logo} alt="Logo" /></Link>
                </div>
                <Alert key="danger" variant={props.variant} show={props.alertshow}>
                    {props.message}
                </Alert>
                <div className="main-box-inner boxShadow">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Card
