import { useEffect, useState } from "react";
import { API_URL } from "../../services/Api_endPoint";
import { investorVerifyMutationQuery } from "../../services/Query";
// import { Alert } from "react-bootstrap";
import axios from "axios";
import { Button1, Card } from "../../components";
import { Link } from "react-router-dom";

const EmailVerification = () => {
  const [alertshow, showAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("danger");

  const investorVerifyDigishareMarketplace = async () => {
    const url = window.location.href
    const secretCode = url.substring(url.lastIndexOf('/') + 1)
    const response = await axios.post(API_URL,
      {
        query: investorVerifyMutationQuery,
        variables: {
          "secret": secretCode
        }
      }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.data.hasOwnProperty("errors")) {
      console.log(response.data);
      setVariant("danger");
      showAlert(true);
      setMessage(response.data.errors[0].message);
    }
    else if (response.data.hasOwnProperty("data")) {
      setVariant("success");
      showAlert(true);
      setMessage("Email verification succesfull.");
    }
  }

  useEffect(() => {
    investorVerifyDigishareMarketplace()
  }, [])
  return (
    // <section className="pageHeight">
    //   <Alert key="danger" variant={variant} show={alertshow}>
    //     {message}
    //   </Alert>

    // </section>

    <Card variant={variant} alertshow={alertshow} message={message}>
      <h5 className="text-center">
        Your account has been verified.
      </h5>
      <Link to="/login" className="d-flex justify-content-center">
      <Button1 style={{ backgroundColor: 'var(--secondary)' }}>Login</Button1>
      </Link>
    </Card>
  )
}

export default EmailVerification
