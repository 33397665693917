import './HowitWorks.scss';
import { PageBanner } from '../../../components';
import backgroundImage from '../../../assets/images/hiw-banner.jpg'

import hiwIcon1 from '../../../assets/images/hiw-icon_01.png';
import hiwIcon2 from '../../../assets/images/hiw-icon_02.png';
import hiwIcon3 from '../../../assets/images/hiw-icon_03.png';
import hiwIcon4 from '../../../assets/images/hiw-icon_04.png';
import hiwIcon5 from '../../../assets/images/hiw-icon_05.png';
import hiwIcon6 from '../../../assets/images/hiw-icon_06.png';
import hiwIcon7 from '../../../assets/images/hiw-icon_07.png';
import hiwIcon8 from '../../../assets/images/hiw-icon_08.png';
import hiwIcon9 from '../../../assets/images/hiw-icon_09.png';
import hiwIcon10 from '../../../assets/images/hiw-icon_10.png';


const HowitWorks = () => {

  const iconRight = (iconSrc, iconAlt, header, para) => (
    <div className="container hiw-row-block">
      <div className="row justify-content-between">
        <div className="col-8 hiw-contents">
          <h4>{header}</h4>
          <p>{para}</p>
        </div>
        <div className="col-3 hiw-icon leftBorder">
          <img src={iconSrc} alt={iconAlt} />
        </div>
      </div>
    </div>
  )

  const iconLeft = (iconSrc, iconAlt, header, para) => (
    <div className="container hiw-row-block">
      <div className="row justify-content-between">
        <div className="col-3 hiw-icon rightBorder">
          <img src={iconSrc} alt={iconAlt} />
        </div>
        <div className="col-8 hiw-contents text-end">
          <h4>{header}</h4>
          <p>{para}</p>
        </div>
      </div>
    </div>
  )

  const projectOnboardingBlock = (iconSrc, iconAlt, backgroundColor, header, para) => (
    <div className='project-onboarding-block' style={{ backgroundColor: `${backgroundColor}` }}>
      <div><img src={iconSrc} alt={iconAlt} /></div>
      <h5>{header}</h5>
      <p>{para}</p>
    </div>
  )
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='HOW IT WORKS'
        para='Institutional-Quality Assets marketplace Calibrated for accessibility, Safety, transparency, Low Fees and Management Efficiency.'
        buttonText=''
      />

      <section className="sectionPaddingY inv-cont">
        <div className="container">
          <div className="content container-inn">
            <h5>Simple, and secured Institutional-Quality Investments with low minimum cost requirements, Powered by Technology</h5>
            <p>Capitaleta makes it simpler, safer, and more transparent for you to own institutional-quality projects at a low minimum requirement. Investment-ready projects are unitized into 5,000 shares of ownership and made available for purchase on the Capitaleta market for as little as $1,000 per share. You can now become a part owner of high-quality projects that are calibrated for profitability and professionally managed. Capitaleta guarantees investors a steady flow of high-quality projects sourced professionally.</p>
          </div>
        </div>
      </section>

      <section className='sectionPaddingY'>
        <div className='mb-3'>
          {
            iconRight(hiwIcon6, 'hiwIcon6', 'Register to Join', 'Registration on Capitaleta marketplace gives you access to every project offered for investment on our platform and completing your Know Your Client (KYC) allows you to invest in any project in the marketplace Upon registration, Capitaleta provides all members with a payment wallet to make payment s and funds transfer easy.')
          }
        </div>
        <div className='mb-3'>
          {
            iconLeft(hiwIcon7, 'hiwIcon7', 'Fund your Wallet to Invest', 'Connect your wallet to your bank account to be ready to take advantage of project opportunities when they arrive in the marketplace. Projects sell out fast!')
          }
        </div>
        <div className='mb-3'>
          {
            iconRight(hiwIcon8, 'hiwIcon8', 'Choose a Project to Invest', 'Browse our marketplace to select from available project investment opportunities - review due diligence documents and Offering Memorandum. on each project opportunity. We are always available to help you.')
          }
        </div>
        <div className='mb-3'>
          {
            iconLeft(hiwIcon9, 'hiwIcon9', 'Sign and Invest', 'Decide on the amount to invest, sign the subscription agreement and make payment by simply moving funds from your wallet to the Capitaleta account.')
          }
        </div>
        <div>
          {
            iconRight(hiwIcon10, 'hiwIcon10', 'Congratulations! View your Portfolio', 'Log back into your account on Capitaleta Marketplace to view your new portfolio and holdings.')
          }
        </div>
      </section>

      <section className="sectionPaddingY project-onboarding">
        <div className="container">
          <div className="row">
            <h2 className='topicHeading text-center'>Project Onboarding Process</h2>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              {
                projectOnboardingBlock(hiwIcon1, 'hiwIcon1', 'var(--primary)', '1. Deal Sourcing and Analysis', 'CapitaLeta Sector Operation Team (SOT) identifies project opportunities and completes the preliminary due diligence process. Investment decisions are made collectively by our Investment Committee, whose members have a good track record of investing in the sector and managing such projects. All opportunities go through our detailed due diligence process when approved for consideration.')
              }
              {
                projectOnboardingBlock(hiwIcon2, 'hiwIcon2', 'var(--secondary)', '2. Due Diligence and Risks Underwriting', 'At this stage, we complete the project bankability review, sponsors\' and project due diligence, detailed project analysis and our three-step risks underwriting process. Once the project underwriting is finished and approved by our Investment Committee, we enter into an investment agreement with the project sponsor.')
              }
            </div>
            <div className="col-12 col-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className='text-center'>
                <img src={hiwIcon5} alt="hiwIcon5" />
                <h5 className='midHeading'>ONBOARDING A <br />PROJECT</h5>
              </div>
            </div>
            <div className="col-12 col-md-4">
              {
                projectOnboardingBlock(hiwIcon3, 'hiwIcon3', 'var(--secondary)', '3. Project Onboarding on Marketplace', 'While the project cannot directly be fractionalized, the legal entities that own the asset can. CapitaLeta fractionalizes the ownership before onboarding the project in units on the CapitaLeta marketplace. Our marketplace, powered by cutting-edge technology, enables project accessibility, transparency, liquidity, speed, monitoring, reporting, and management efficiency.​')
              }
              {
                projectOnboardingBlock(hiwIcon4, 'hiwIcon4', 'var(--primary)', '4. Offering to Investors', 'The fractionalized units of the project opportunity offered for investment are listed for sale on the CapitaLeta marketplace to investors at a low minimum investment of as low as $1,000 per unit. Investors can decide how many multiples of $1,000 they want to invest per project. Investors are permitted to participate in many projects concurrently.​')
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HowitWorks
