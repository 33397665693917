import { PageBanner } from '../../../components'
import backgroundImage from '../../../assets/images/impact-banner.jpg'

const PostApproval = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='Capital Raise With Capitaleta'
        para='CapitaLeta uses our innovative technology platform to simplify and automate the financing of your projects, from issuing funding approval packages to ongoing support.'
        buttonText=''
      />
    </>
  )
}

export default PostApproval
