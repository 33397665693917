import React from 'react'

const Disclosures = () => {
  return (
    <div>
      
    </div>
  )
}

export default Disclosures
