import './Modal.scss';
import ReactDOM from 'react-dom';

const Backdrop = props => {
    return <div className="backdrop" onClick={()=>props.onClose(false)} />
}

const ModalOverlay = props => {
    return <div className="modalcss">
        <div className="content">
            {props.children}
        </div>
    </div>
}

const potalElement = document.getElementById('overlays')

const Modal = (props) => {
    return (
        <>
            {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, potalElement)}
            {ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, potalElement)}
        </>
    )
}

export default Modal
