import { Link } from 'react-router-dom';
import { featuredProjectItems } from '../../common/Constants';
import Button1 from '../UI/Button1/Button1';
import './FeaturedProject.scss';

const FeaturedProject = () => {
    return (
        <section className='featured-project sectionPaddingY'>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center featured-project-heading">
                        <h2 className='topicHeading'>Featured Project</h2>
                        <h4 className='smHeading'>Capitaleta makes Investing in projects Simple, Accessible, and affordable.</h4>
                        <p>We help project sponsors raise capital more efficiently, and give investors access to secure and profitable project investments.</p>
                    </div>
                </div>
                <div className='featured-project-properties-list'>
                    <div className='row'>
                        {
                            featuredProjectItems.map((item, index) => (
                                <div key={index} className='col-12 col-lg-4'>
                                    <div className='featured-project-property'>
                                        <div className='featured-project-property-description'>
                                            <h5>{item.heading}</h5>
                                            <p><i class="bi bi-geo-alt"></i>{item.location}</p>
                                            <div className={`p-status ${item.status === 'Live' ? 'live-status' : 'closed-status'}`} >
                                                <span className='status live'><span className='bullet-icon'><i class="bi bi-circle-fill"></i></span> <span className='status-text'>{item.status}</span></span>
                                            </div>
                                        </div>
                                        <div className='featured-project-property-img'>
                                            <img src={item.imgSrc} alt={item.imgAlt} />
                                            <div className='invest-amt'>
                                                <h6>Invested: {item.investedAmd}</h6>
                                            </div>
                                        </div>
                                        <div className='featured-project-property-specification'>
                                            <div className='featured-project-p-short-desc'><p>{item.shortDesc}</p></div>
                                            <div className='featured-project-p-spec'>
                                                <ul className='list-bg-color'>
                                                    <li><span className='spec-name'>Investment Term</span><span className='spec-qty'>{item.investmentTerm}</span></li>
                                                    <li><span className='spec-name'>Min Investment Amount</span><span className='spec-qty'>{item.minInvestmentAmount}</span></li>
                                                    <li><span className='spec-name'>Projected Annual Return</span><span className='spec-qty'>{item.projectedAnnualReturn}</span></li>
                                                    <li><span className='spec-name'>Target Average Cash Yield</span><span className='spec-qty'>{item.targetAverageCashYield}</span></li>
                                                    <li><span className='spec-name'>Target Equity Multiple</span><span className='spec-qty'>{item.targetEquityMultiple}</span></li>
                                                    <li><span className='spec-name'>Investor Equity Raise</span><span className='spec-qty'>{item.investorEquityRaise}</span></li>
                                                </ul>
                                            </div>
                                            <div className="featured-project-button">
                                                <Link to='/projectdetails'>
                                                    <Button1 style={{ backgroundColor: 'var(--primary)' }}>
                                                        View Project Details <i class="bi bi-arrow-right"></i>
                                                    </Button1>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedProject
