export const FINDALLSTO_QUERY = `query FindAllSto {
    findAllSto {
      ID
      isActive
      logo
      logoUrl
      meta {
        display
        key
        order
        stoID
        value
      }
      picture
      details
      title
    }
  }`;

//SIGN UP
export const signUpMutationQuery = `mutation SignUp($data: SignUpInput!) {
    signUp(data: $data)
}`;

//SIGN IN
export const signInMutationQuery = `mutation SignIn($stoId: Int!, $password: String!, $email: String!) {
    signIn(stoID: $stoId, password: $password, email: $email)
  }`;

//EMAIL VERIFICATION
export const investorVerifyMutationQuery = `mutation InvestorVerify($secret: String!) {
  investorVerify(secret: $secret)
}`;

export const INVESTOR_USER_DETAILS_QUERY = `query InvestorUser {
  investorUser {
    investor {
      address
      birthDate
      country
      email
      firstName
      lastName
      middleName
      phone
      zip
      state
      town
      nationalID
      driversLicenseID
      investorType
    }
  }
}`;

export const signInSSOMutation = `mutation SignInSSO($data: SignInSSOInput!) {
    signInSSO(data: $data)
}`;


// KYCPage
export const COUNTRIES_QUERY = `query Query {
  countries
}`;


export const INVESTOR_PROFILE_UPDATE = `mutation InvestorProfile($data: InvestorProfileInput!) {
    investorProfile(data: $data)
  }`;

export const INVESTOR_KYC_UPDATE = `mutation FillKyc($data: JSON!) {
  fillKyc(data: $data)
}`;

export const INVESTOR_KYC_DETAILS = `query Query {
  investorKyc
}`

export const APPLY_KYC = `mutation ApplyKyc($applied: Boolean!) {
  applyKyc(applied: $applied)
}`;

export const uploadMutation = `{"query":"mutation FileUpload($file: Upload!) {fileUpload(file: $file) {link name}}","variables":{"file":null}}`;


//GET KYC STATUS

export const INVESTOR_STO = `query InvestorSto($stoId: Int) {
  investorSto(stoID: $stoId) {
    ID
    isAccountClosed
    investorID
    stoID
    expectedShares
    expectedInvestment
    isKYC
    applied
    status
    isUsufructuary
    isActive
    isNDASigned
    isBuyStoEnabled
  }
}`