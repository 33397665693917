import './ProjectReview.scss';
import { PageBanner } from '../../../components';
import backgroundImage from '../../../assets/images/prp-banner.jpg';
import step1Img from '../../../assets/images/prp-step1.jpg';
import step2Img from '../../../assets/images/prp-step2.jpg';
import step3Img from '../../../assets/images/prp-step3.jpg';
import step4Img from '../../../assets/images/prp-step4.jpg';
import step5Img from '../../../assets/images/prp-step5.jpg';

const ProjectReview = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='WE ARE LASER-FOCUSED ON EXCELLENCE'
        para='We work with project sponsors with institutional-quality real estate and energy projects. The project and sponsor are put through a thorough evaluation process by our Sector Operation Team to determine if they are a good fit for our Marketplace.'
        buttonText=''
      />

      <section className='sectionPaddingY'>
        <h2 className='topicHeading text-center'>Project Review Process</h2>
        <div className="container">
          <div>
            <div className="text-center" style={{ color: 'var(--secondary)', fontSize: '1.5rem' }}>1</div>
            <div className="row prp-steps">
              <div className="col-12 col-lg-6 prp-steps-img-block">
                <div className="prp-steps-img" style={{ backgroundImage: `url(${step1Img})` }}></div>
              </div>
              <div className="col-12 col-lg-6 prp-steps-text">
                <h5>Project Sponsor Assessment</h5>
                <p>We work with project sponsors that have a solid reputation for producing top-notch work.  Each sponsor must then go through the assessment process.</p>
                <p>Background Investigation: We conduct an extensive background investigation on both the firm and the principals.</p>
                <p>Review of Track Record: We confirm that the sponsor has successfully completed projects in the asset type and class that they want to add to the Marketplace.​</p>
                <p>Project Sponsor classification: We categorize project sponsors into three groups depending on their history and expertise, which helps define the resource to be provided to the sponsor category.</p>
              </div>
            </div>
          </div>
          <div>
            <div className="text-center" style={{ color: 'var(--secondary)', fontSize: '1.5rem' }}>2</div>
            <div className="row prp-steps">
              <div className="col-12 col-lg-6 order-1 order-lg-0 prp-steps-text">
                <h5>ESG Assessments</h5>
                <p>Environment, social, and governance (ESG) issues are important to CapitaLeta. They play a crucial role in our decision-making process when it comes to investing in a project. Due to the long-term nature of the investment, ESG components are one of our keys to assessing the risk in a potential opportunity.</p>
                <p>Our ESG assessment gauges how resilient project prospects are to long-term ESG threats. Depending on how exposed the project is to ESG risks and how successfully they are handled, we apply a rules-based technique to identify leading and lagging indicators as follows:</p>
                <h6>Environment:</h6>
                <p>Environmental concerns are crucial to project development and can intersect with social concerns in neighbourhood interactions. Impacts of project siting, community connections, ecological justice, choice of use, disposal, and material recycling are all considered in our review.</p>
                <h6>Social:</h6>
                <p>The social parts of our evaluation consider staff working conditions, work stoppage threats, modern slavery, labour exploitation vulnerability, supply chain consideration, and Diversity, Equity, and Inclusion (DEI).</p>
                <h6>Governance:</h6>
                <p>Our Governance review includes traditional conceptions of corporate governance as well as the frameworks used by the project sponsor to handle important areas of risk in a project, such as transaction requirements imposed by lenders and sponsors, cybersecurity and data protection, anti-corruption, and trade compliance.</p>
              </div>
              <div className="col-12 col-lg-6 prp-steps-img-block">
                <div className="prp-steps-img" style={{ backgroundImage: `url(${step2Img})` }}></div>
              </div>
            </div>
          </div>
          <div>
            <div className="text-center" style={{ color: 'var(--secondary)', fontSize: '1.5rem' }}>3</div>
            <div className="row prp-steps">
              <div className="col-12 col-lg-6 prp-steps-img-block">
                <div className="prp-steps-img" style={{ backgroundImage: `url(${step3Img})` }}></div>
              </div>
              <div className="col-12 col-lg-6 prp-steps-text">
                <h5>The Project Assessment</h5>
                <p>When reviewing a transaction offered by a project sponsor, we analyze and confirm:</p>
                <p>Project suitability: The project provided meets the asset type, location, and economic metrics preferences of Capitaleta and its investors.​</p>
                <p>Competencies: We ensure that the project is within the sponsor's core competency.​</p>
                <p>Project Presentation: The project materials given by the sponsor reflect professionalism and satisfy industry standards.</p>
                <p>Assumptions: We confirm that market data back the assumption in the financial package. </p>
              </div>
            </div>
          </div>
          <div>
            <div className="text-center" style={{ color: 'var(--secondary)', fontSize: '1.5rem' }}>4</div>
            <div className="row prp-steps">
              <div className="col-12 col-lg-6 order-1 order-lg-0 prp-steps-text">
                <h5>Project Document Evaluation</h5>
                <p>We go over the transactional paperwork to make sure there are no mistakes or inconsistencies with the figures, our interpretation of the paperwork, or any communications between Capitaleta and the project sponsor.​</p>
                <p>We Ask Questions: Before the due diligence process is finished, we clarify any misunderstandings and bring up any problems with the sponsor.​</p>
                <p>​Feedback and Suggestions: When appropriate, our SOT offers specific feedback and recommendations to make the deal adhere to our requirements.​</p>
                <p>Sponsor’s Decision: Sponsors have the option to change the conditions or documents based on our recommendation or withdraw their proposal for review.</p>
              </div>
              <div className="col-12 col-lg-6 prp-steps-img-block">
                <div className="prp-steps-img" style={{ backgroundImage: `url(${step4Img})` }}></div>
              </div>
            </div>
          </div>
          <div>
            <div className="text-center" style={{ color: 'var(--secondary)', fontSize: '1.5rem' }}>5</div>
            <div className="row prp-steps">
              <div className="col-12 col-lg-6 prp-steps-img-block">
                <div className="prp-steps-img" style={{ backgroundImage: `url(${step5Img})` }}></div>
              </div>
              <div className="col-12 col-lg-6 prp-steps-text">
                <h5>Project Risks Evaluation</h5>
                <p>When negotiating investment opportunities, one of our primary goals is to identify each material risk involved with the project's design, construction, development, and operation. We are interested in finding which participant is best suited to bear such risks and the mechanisms that will allow it to do so.</p>
                <h6>Construction:</h6>
                <p>Every project is unique, and each has its risks. Ensuring a project's appropriate, timely, and on-budget construction is a critical factor for our investment team because it influences loan payback and investor return.​</p>
                <h6>Operational:</h6>
                <p>We select skilled project operators who promise performance and agree to thorough inspection and reporting to ensure that the project generates the revenues required to meet investors' profit expectations.​</p>
                <h6>Offtake</h6>
                <p>As part of our risk assessment, we look at whether a project has a ready market for its project and whether the project off-takers can pay on time. We pay great attention to off-take risks because they can diminish investment profits.</p>
                <h6>Repayment</h6>
                <p>A project that generates inadequate income owing to off-take risk or other factors may fail to fulfil the needs of both lenders and investors.</p>
                <p>We work with sponsors to mitigate such risks, ensuring that lenders and investors receive the payments and amounts owed to them.</p>
                <p>We take various project-related risks into account based on the nature of the projects and the applicable jurisdiction. The most common local risks are outlined above, but others can be currency, supply authorization, political, and dispute resolution risks. We have strategies to mitigate these risks when we encounter them.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='sectionPaddingY secondaryColorSection'>
        <div className="container">
          <p>Depending on the nature of the projects and jurisdiction, we consider other risks that can impact a project. The most common threat we encounter locally is outlined above, but others can be currency risk, supply authorization, political, and dispute resolution risks. We have strategies to mitigate these risks when we encounter them.</p>
        </div>
      </section>
    </>
  )
}

export default ProjectReview
