import { Link } from 'react-router-dom'
import Button1 from '../UI/Button1/Button1'
import './PageBanner.scss'

const PageBanner = (props) => {
  return (
    <div className='page-banner'>
      <div className="banner" style={props.style}></div>
      <div className="banner-text">
        <div className="container">
          <div className="row sectionPaddingY ">
            <h4>{props.heading}</h4>
            {
              props.para &&
              <p>{props.para}</p>
            }
            {
              props.buttonText &&
              <div className='d-flex justify-content-center'>
                <Link to={props.buttonLink}>
                  <Button1 style={{ backgroundColor: 'var(--primary)' }}>
                    {props.buttonText} <i class="bi bi-arrow-right"></i>
                  </Button1>
                </Link>
              </div>
            }
            <div className='border-orange-line'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageBanner
