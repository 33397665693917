import { PageBanner } from '../../../components'
import backgroundImage from '../../../assets/images/pep-banner.jpg'

const ProjectEvaluation = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='Quality Project Sponsors to Ensure High-Quality Deal Flow'
        para='Our project evaluation process involves selecting investment opportunities that meet our investor and market quality criteria. About 7 out of 100 projects make it into the capital raising phase.'
        buttonText=''
      />
    </>
  )
}

export default ProjectEvaluation
