import './Button1.scss';

const Button1 = (props) => {
    return (
        <div className='btn1' style={props.style} onClick={props.onClick}>
            {props.children}
        </div>
    )
}

export default Button1
