import React from 'react'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import { myEventsList } from '../../common/EventsWebinersConstants';
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import 'react-big-calendar/lib/css/react-big-calendar.css';

const EventsWebiners = () => {
  const locales = {
    'en-US': enUS,
  }
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
  const headingStyle = {
    color: 'var(--secondary)',
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: '2.75rem',
  }

  return (
    <div className='container mt-5 mb-5'>
      <h4 style={headingStyle}>Events and Webiners</h4>
      <div className='eventswrapper mt-4'>
        <Calendar
          localizer={localizer}
          events={myEventsList}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      </div>
    </div>
  )
}

export default EventsWebiners
