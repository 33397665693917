import whyinvImg1 from '../assets/images/whyinv-img1.jpg';
import whyinvImg2 from '../assets/images/whyinv-img2.jpg';
import whyinvImg3 from '../assets/images/whyinv-img3.jpg';

export const threeBlockContent = [
    {
        color: 'var(--secondary)',
        haeding: 'Transparency and Reporting',
        imgSrc: whyinvImg1,
        imgAlt: 'whyinvImg1',
        para: 'CapitaLeta’s most outstanding characteristics stem from its transaction ledger, which is open to viewing by investors. This feature adds an unprecedented layer of accountability, holding CapitaLeta responsible for acting with integrity towards the Company’s growth, its investors and project sponsors.'
    },
    {
        color: 'var(--primary)',
        haeding: 'Choice and Control',
        imgSrc: whyinvImg2,
        imgAlt: 'whyinvImg2',
        para: 'In most investments, you are entirely dependent on the decisions of the company’s senior management in terms of the projects they choose to invest in or dispose of. It is not so with CapitaLeta, where you have full control and you make the choice best for you.'
    },
    {
        color: 'var(--secondary)',
        haeding: 'Unique Ownership',
        imgSrc: whyinvImg3,
        imgAlt: 'whyinvImg3',
        para: 'The ownership of each project on CapitaLeta is distributed across a finite number of representative units. each share unit represents ownership. Owners can receive their returns on their investment and vote on project decisions.'
    },
]