import "./Marketplace.scss";
// import { useEffect, useState } from "react";
// import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
// import Auth from "../../services/Auth";
// import { FINDALLSTO_QUERY } from "../../services/Query";
import Button1 from "../UI/Button1/Button1";

import propertyImg1 from "../../assets/images/property-img_03.png";
// import propertyImg1 from '../../assets/images';
import propertyImg2 from "../../assets/images/property-img_05.png";
import propertyImg3 from "../../assets/images/property-img_07.png";

const projects = [
  {
    status: "Close",
    value: "12,222",
    name: "Project 1",
    loaction: "Canada",
    picture: propertyImg1,
    listDetails: [
      {
        title: "Inventment Term",
        value: "12 months",
      },
      {
        title: "min investment Amount(cad)",
        value: "e.g. 55,000",
      },
      {
        title: "Projected annual return",
        value: "e.g. 8.25%",
      },
    ],
    detailsPara1:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio accusamus magnam, aliquam assumenda incidunt ex ut veritatis eos in saepe.",
    detailsPara2:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio.",
  },
  {
    status: "Live",
    value: "12,222",
    name: "Project 2",
    loaction: "Canada",
    picture: propertyImg2,
    listDetails: [
      {
        title: "Inventment Term",
        value: "12 months",
      },
      {
        title: "min investment Amount(cad)",
        value: "e.g. 55,000",
      },
      {
        title: "Projected annual return",
        value: "e.g. 8.25%",
      },
    ],
    detailsPara1:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio accusamus magnam, aliquam assumenda incidunt ex ut veritatis eos in saepe.",
    detailsPara2:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio.",
  },
  {
    status: "Live",
    value: "12,222",
    name: "Project 3",
    loaction: "Canada",
    picture: propertyImg3,
    listDetails: [
      {
        title: "Inventment Term",
        value: "12 months",
      },
      {
        title: "min investment Amount(cad)",
        value: "e.g. 55,000",
      },
      {
        title: "Projected annual return",
        value: "e.g. 8.25%",
      },
    ],
    detailsPara1:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio accusamus magnam, aliquam assumenda incidunt ex ut veritatis eos in saepe.",
    detailsPara2:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio.",
  },
  {
    status: "Close",
    value: "12,222",
    name: "Project 1",
    loaction: "Canada",
    picture: propertyImg1,
    listDetails: [
      {
        title: "Inventment Term",
        value: "12 months",
      },
      {
        title: "min investment Amount(cad)",
        value: "e.g. 55,000",
      },
      {
        title: "Projected annual return",
        value: "e.g. 8.25%",
      },
    ],
    detailsPara1:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio accusamus magnam, aliquam assumenda incidunt ex ut veritatis eos in saepe.",
    detailsPara2:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio.",
  },
  {
    status: "Live",
    value: "12,222",
    name: "Project 2",
    loaction: "Canada",
    picture: propertyImg2,
    listDetails: [
      {
        title: "Inventment Term",
        value: "12 months",
      },
      {
        title: "min investment Amount(cad)",
        value: "e.g. 55,000",
      },
      {
        title: "Projected annual return",
        value: "e.g. 8.25%",
      },
    ],
    detailsPara1:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio accusamus magnam, aliquam assumenda incidunt ex ut veritatis eos in saepe.",
    detailsPara2:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio.",
  },
  {
    status: "Live",
    value: "12,222",
    name: "Project 3",
    loaction: "Canada",
    picture: propertyImg3,
    listDetails: [
      {
        title: "Inventment Term",
        value: "12 months",
      },
      {
        title: "min investment Amount(cad)",
        value: "e.g. 55,000",
      },
      {
        title: "Projected annual return",
        value: "e.g. 8.25%",
      },
    ],
    detailsPara1:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio accusamus magnam, aliquam assumenda incidunt ex ut veritatis eos in saepe.",
    detailsPara2:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab voluptatum facilis placeat. Architecto sequi sunt dignissimos accusantium officia, distinctio.",
  },
];

const Marketplace = () => {
  //   const [spinner, setLoaderVisibility] = useState(false);
  //   const [stohtml, setHTML] = useState();

  //   const renderSTO = (stoColl) => {
  //     const html = stoColl.map((item, index) => (
  //       <div className="col-12 col-lg-4" key={index}>
  //         <div className="cl-property">
  //           <div className="cl-property-description">
  //             <h5>{item.name}</h5>
  //             <p>
  //               <i class="bi bi-geo-alt"></i>Atlanta, Georgia
  //             </p>
  //             <div className="p-status live-status">
  //               <span className="status live">
  //                 <span className="bullet-icon">
  //                   <i class="bi bi-circle-fill"></i>
  //                 </span>{" "}
  //                 <span className="status-text">Live</span>
  //               </span>
  //               <span className="status closed d-none">
  //                 <span className="bullet-icon">
  //                   <i class="bi bi-circle-fill"></i>
  //                 </span>{" "}
  //                 <span className="status-text">Closed</span>
  //               </span>
  //             </div>
  //           </div>
  //           <div className="cl-property-img">
  //             <img src={item.picture} alt="" />
  //           </div>
  //           <div className="cl-property-specification">
  //             <div className="cl-p-short-desc">
  //               <p>{item.desc}</p>
  //             </div>
  //             <div className="cl-p-spec">
  //               <ul className="list-bg-color">
  //                 <li>
  //                   <span className="spec-name">Investment Term</span>
  //                   <span className="spec-qty">12 Months</span>
  //                 </li>
  //                 <li>
  //                   <span className="spec-name">Min Investment Amount</span>
  //                   <span className="spec-qty">$1,000</span>
  //                 </li>
  //                 <li>
  //                   <span className="spec-name">Projected Annual Return</span>
  //                   <span className="spec-qty">8.25%</span>
  //                 </li>
  //                 <li>
  //                   <span className="spec-name">Target Average Cash Yield</span>
  //                   <span className="spec-qty">4.50%</span>
  //                 </li>
  //                 <li>
  //                   <span className="spec-name">Target Equity Multiple</span>
  //                   <span className="spec-qty">1.75x</span>
  //                 </li>
  //                 <li>
  //                   <span className="spec-name">Investor Equity Raise</span>
  //                   <span className="spec-qty">3.35x</span>
  //                 </li>
  //               </ul>
  //             </div>

  //             <Link to={`/projectdetails/${item.ID}`}>
  //               <Button1
  //                 style={{
  //                   backgroundColor: "var(--primary)",
  //                   textAlign: "center",
  //                   margin: "0 15px 0 15px",
  //                 }}
  //               >
  //                 View Project Details <i class="bi bi-arrow-right"></i>
  //               </Button1>
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //     ));
  //     setHTML(html);
  //     setLoaderVisibility(false);
  //   };

  // const { http, APIToken } = Auth();
  // useEffect(() => {
  //     let apitoken = APIToken();
  //     if (apitoken) {
  //         const findAllSTO = async () => {
  //             const response = await http.post("", {
  //                 query: FINDALLSTO_QUERY
  //             }, {
  //                 headers: {
  //                     "Authorization": "Bearer " + apitoken
  //                 }
  //             });
  //             console.log(response);
  //             if (response.hasOwnProperty("data")) {
  //                 renderSTO(response.data.data.findAllSto);
  //             }
  //         }
  //         findAllSTO();
  //     }
  // }, []);
  return (
    // <div className="innerpage-container">
    //   {spinner === true && (
    //     <div
    //       style={{
    //         textAlign: "center",
    //         minHeight: "60vh",
    //         position: "relative",
    //       }}
    //     >
    //       <Oval
    //         visible={true}
    //         height="50"
    //         width="50"
    //         wrapperStyle={{
    //           textAlign: "center",
    //           display: "block",
    //           position: "absolute",
    //           margin: 0,
    //           top: "50%",
    //           left: "50%",
    //         }}
    //         color="#eb641e"
    //         secondaryColor="#183a65"
    //       />
    //     </div>
    //   )}
    //   {spinner === false && (
    <>
      {/* <div className='inner-banner marketplace-page'>
                        <div className='inner-banner-caption'>
                            <div className='container'>
                                <div className='filter-form-block'>
                                    <div className='row items-center'>
                                        <div className='col-12 col-lg-2 mb-3 mb-lg-0'><h6>Search Properties</h6></div>
                                        <div className='col-6 col-lg-2 mb-3 mb-lg-0'>
                                            <div className='cl-select'>
                                                <select className="form-select" aria-label="Default select example" defaultValue={'DEFAULT'}>
                                                    <option value="DEFAULT" disabled>Select Property</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-2 mb-3 mb-lg-0'>
                                            <div className='cl-select'>
                                                <select className="form-select" aria-label="Default select example" defaultValue={'DEFAULT'}>
                                                    <option value="DEFAULT" disabled>Select Country</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-2'>
                                            <div className='cl-select'>
                                                <select className="form-select" aria-label="Default select example" defaultValue={'DEFAULT'}>
                                                    <option value="DEFAULT" disabled>Select States</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-2'>
                                            <div className='cl-select'>
                                                <select className="form-select" aria-label="Default select example" defaultValue={'DEFAULT'}>
                                                    <option value="DEFAULT" disabled>Select Cities</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-2 mt-3 mt-lg-0'>
                                            <Button1 style={{ backgroundColor: 'var(--secondary)', textAlign: 'center' }}><i className="bi bi-search"></i> Search</Button1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
      <div className="innerpage-contents sectionPaddingY">
        <div className="container">
          <div className="cl-properties">
            <h6>INVESTABLE PROJECTS</h6>
            <p className="topicHeading">Recent Projects</p>
            <div className="cl-properties-list">
              <div className="row">
                {projects.map((item, index) => (
                  // <div
                  //   className="col-12 p-0 col-lg-4 cl-properties-item"
                  //   key={index}
                  // >
                  //   <div className="cl-property-img">
                  //     <img src="" alt="" />
                  //   </div>
                  //   <div className="statusBar p-2 d-flex justify-content-between">
                  //     <p className="m-0 bg-white p-1 rounded">
                  //       {" "}
                  //       <i class="bi bi-circle-fill"></i>{" "}
                  //       <span>{item.status}</span>
                  //     </p>
                  //     <p className="m-0 bg-white p-1 rounded">${item.value}</p>
                  //   </div>
                  //   <div className="p-3">
                  //     <h5 className="text-center">
                  //       {item.name} / {item.loaction}
                  //     </h5>
                  //     <ul className="row gap-3 points p-0 mb-4">
                  //       {item.listDetails.map((item, index) => (
                  //         <li className="col p-2 point " key={index}>
                  //           <h6>{item.title}</h6>
                  //           <p className="m-0">{item.value}</p>
                  //         </li>
                  //       ))}
                  //     </ul>
                  //     <h5 className="text-center">Project Description</h5>
                  //     <p className=" details">{item.detailsPara1}</p>
                  //     <p className=" details">{item.detailsPara2}</p>
                  //   </div>
                  //   <div className="detailsBtn py-2">
                  //     <button className="btn bg-white">More Information</button>
                  //   </div>
                  // </div>
                  <div className="col-12 col-lg-4" key={index}>
                    <div className="cl-property">
                      <div className="cl-property-description">
                        <h5>{item.name}</h5>
                        <p>
                          <i class="bi bi-geo-alt"></i>
                          {item.loaction}
                        </p>
                        <div className={`p-status ${item.status}-status`}>
                          {item.status === "Live" && (
                            <span className="status live">
                              <span className="bullet-icon">
                                <i class="bi bi-circle-fill"></i>
                              </span>{" "}
                              <span className="status-text">Live</span>
                            </span>
                          )}
                          {item.status === "Close" && (
                            <span className="status closed">
                              <span className="bullet-icon">
                                <i class="bi bi-circle-fill"></i>
                              </span>{" "}
                              <span className="status-text">Closed</span>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="cl-property-img">
                        <img src={item.picture} alt="" />
                      </div>
                      <div className="cl-property-specification mt-2">
                        {/* <div className="cl-p-short-desc">
                          <p>{item.desc}</p>
                        </div> */}
                        <div className="cl-p-spec">
                          <ul className="list-bg-color mx-auto text-center row">
                            {item.listDetails.map((item, index) => (
                              <li className="col-4 d-flex flex-column">
                                <span className="spec-name fw-bold ">
                                  {item.title}
                                </span>
                                <span className="spec-qty ">{item.value}</span>
                              </li>
                            ))}
                          </ul>
                          <h6 className="text-black fs-5 text-center py-3">
                            Project Details
                          </h6>
                          <p className="detailsPara">{item.detailsPara1}</p>
                          <p className="detailsPara">{item.detailsPara2}</p>
                        </div>

                        <Link to={`/projectdetails/${item.ID}`}>
                          <Button1
                            style={{
                              backgroundColor: "var(--primary)",
                              textAlign: "center",
                              margin: "0 15px 0 15px",
                            }}
                          >
                            More Details <i class="bi bi-arrow-right"></i>
                          </Button1>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="col-12 p-0  col-lg-4 cl-properties-item">
                  <div className="cl-property-img">
                    <img src="" alt="" />
                  </div>
                  <div className="statusBar p-2 d-flex justify-content-between">
                    <p className="m-0 bg-white p-1 rounded">
                      {" "}
                      <i class="bi bi-circle-fill"></i> <span>Live</span>
                    </p>
                    <p className="m-0 bg-white p-1 rounded">$12,111</p>
                  </div>
                  <div className="p-3">
                    <h5 className="text-center">Project Name / Location</h5>
                    <ul className="row gap-3 points p-0 mb-4">
                      <li className="col p-2 point ">
                        <h6>sbskj</h6>
                        <p className="m-0">dhgdhb</p>
                      </li>
                      <li className="col p-2 point">
                        <h6>sbskj</h6>
                        <p className="m-0">dhgdhb</p>
                      </li>
                      <li className="col p-2 point">
                        <h6>sbskj</h6>
                        <p className="m-0">dhgdhb</p>
                      </li>
                    </ul>
                    <h5 className="text-center">Project Name / Location</h5>
                    <p className=" details">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eum voluptatem temporibus molestiae vero. Neque
                      dignissimos adipisci cumque voluptates, odio, culpa fuga
                      voluptate quia cum repellendus quaerat saepe sapiente vel?
                      Neque?
                    </p>
                    <p className=" details">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Ab voluptatum facilis placeat. Architecto sequi sunt
                      dignissimos accusantium officia, distinctio accusamus
                      magnam, aliquam assumenda incidunt ex ut veritatis eos in
                      saepe.
                    </p>
                  </div>
                  <div className="detailsBtn py-2">
                    <button className="btn bg-white">More Information</button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    //   )}
    // </div>
  );
};

export default Marketplace;
