import './Investor.scss';
import { PageBanner, StaticThreeBlock } from '../../../components'
import backgroundImage from '../../../assets/images/investor-banner-main.jpg'
import clientImg from '../../../assets/images/about_24.png';
import { firstThreeBlockContent, secondThreeBlockContent } from '../../../common/InvestorConstants';
import { Link } from 'react-router-dom';

const Investors = () => {
  return (
    <>
      <PageBanner
        style={{ backgroundImage: `url(${backgroundImage})` }}
        heading='Everyone can participate in project investment opportunities through our simple, secured and fully compliant fractional ownership marketplace.'
        para=''
        buttonText='Get Started'
        buttonLink='/login'
      />

      <section className="sectionPaddingY inv-cont">
        <div className="container">
          <div className="content container-inn">
            <h5>An investment platform designed for security, transparency, and long-term profitability</h5>
            <p>Real estate, energy and infrastructure projects are vital components of any well-diversified investment portfolio because they offer distinct value to investors. The Capitaleta marketplace allows you to easily assess and select appropriate projects for your portfolio, as well as track the performance of your investments throughout their investment lifecycles.</p>
          </div>
        </div>
      </section>

      <section className='sectionPaddingY'>
        <StaticThreeBlock items={firstThreeBlockContent} />
      </section>

      <section className="sectionPaddingY inv-cont">
        <div className="container">
          <div className="content container-inn">
            <h5>We Commit to Simplified User Experience, Quality Deal Flow and Timely Information</h5>
            <p>Our investors can be confident we are working to safeguard and grow the capital that is invested in the project on the Capitaleta marketplace. Learn more about our <Link to={'/investors-commitment'}>commitment to investors</Link></p>
          </div>
        </div>
      </section>

      <section className='sectionPaddingY'>
        <StaticThreeBlock items={secondThreeBlockContent} />
      </section>
      <section className='client-say sectionPaddingY'>
        <div className="container-inn main-contain">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-12 col-md-5 mb-2 mb-md-0">
                <img src={clientImg} alt="clientImg" />
              </div>
              <div className="col-12 col-md-6" style={{display: 'flex', alignItems: 'center'}}>
                <div className="client-text">
                  <p>Capitaleta gives me access to transactions and continual deal flow, I am a small business that has been given the ability to diversify in numerous projects that I am pleased with; and Capitaleta gives me possibilities to build my investment portfolio in very interesting ways.</p>
                  <h6>- John Fernandes,<br />President, JCF Capital</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Investors
